import { Dispatch } from "@reduxjs/toolkit";
import { api } from "../config/api";
import { GetState } from "../config/store";
import {
    getMyPublicationsFailure,
    getMyPublicationsSuccess,
    cleanMyPublications as cleanMyPublicationsReducer,
    getMyPublicationsRequest as getMyPublicationsRequestReducer,
    getMyBuysSuccess,
    getMyBuysFailure,
    getMyBuysRequest as getMyBuysRequestReducer,
    cleanMyBuys as cleanMyBuysReducer,
    getMyBuyRequest,
    getMyBuySuccess,
    getMyBuyFailure,
    cleanMyBuy as cleanMyBuyReducer,
    getRegionsRequest,
    getRegionsSuccess,
    getRegionsFailure,
    getCitiesRequest,
    getCitiesSuccess,
    getCitiesFailure,
    getCommunesRequest,
    getCommunesSuccess,
    getCommunesFailure,
    getFavoritesRequest,
    getFavoritesSuccess,
    getFavoritesFailure,
    cleanFavorites as cleanFavoritesReducer,
    setFavorites as setFavoritesReducer,
    likePublicationRequest,
    likePublicationSuccess,
    likePublicationFailure,
    dislikePublicationRequest,
    dislikePublicationSuccess,
    dislikePublicationFailure,
    cleanLikePublication as cleanLikePublicationReducer,
    setPublication,
    getCreditDataRequest,
    getCreditDataSuccess,
    getCreditDataFailure,
    cleanGetCreditData as cleanGetCreditDataReducer,
    getPaymentsRequest,
    getPaymentsSuccess,
    getPaymentsFailure,
    cleanGetPayments as cleanGetPaymentsReducer,
    postPaymentDocumentRequest,
    postPaymentDocumentSuccess,
    postPaymentDocumentFailure,
    cleanPostPaymentDocument as cleanPostPaymentDocumentReducer,
    postPaymentRequest,
    postPaymentSuccess,
    postPaymentFailure,
    cleanPostPayment as cleanPostPaymentReducer,
    setOfferSelected as setOfferSelectedReducer,
    getMyOffersRequest,
    getMyOffersSuccess,
    getMyOffersFailure,
    cleanGetMyOffers as cleanGetMyOffersReducer,
    changePasswordRequest,
    changePasswordSuccess,
    changePasswordFailure,
    cleanSettingsReducer
} from "../reducers/profile";
import {
    patchResidenceRequest,
    patchResidenceSuccess,
    patchResidenceFailure,
    cleanResidenceFetching
} from "../reducers/auth";
import {
    cleanEditUser
} from "../reducers/auth";
import { ResidenceProps } from '../interfaces';
import { axiosClient } from "../config/axios";

export const setOfferSelected = (offer: any) => (dispatch: Dispatch) => _setOfferSelected(offer, dispatch);
const _setOfferSelected = (offer: any, dispatch: Dispatch) => dispatch({ type: setOfferSelectedReducer, payload: offer });

export const getMyPublicationsRequest = (page: number = 1, limit: number = 20) => (dispatch: Dispatch) => _getMyPublicationsRequest(page, limit, dispatch);
const _getMyPublicationsRequest = async (page: number = 1, limit: number = 20, dispatch: Dispatch) => {
    try {        
        dispatch({ type: getMyPublicationsRequestReducer })
        
        const response = await axiosClient.get(`${api.publications}/my-publications?limit=${limit}&page=${page}`);

        dispatch({ 
            type: getMyPublicationsSuccess, 
            payload: { 
                publications: response?.data?.data.publications, 
                total: response?.data?.data.total
            }
        });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getMyPublicationsFailure });
    }
};

export const cleanMyPublications = () => (dispatch: Dispatch) => _cleanMyPublications(dispatch);
const _cleanMyPublications = (dispatch: Dispatch) => dispatch({ type: cleanMyPublicationsReducer });

export const getMyBuysRequest = (page: number = 1, limit: number = 20) => (dispatch: Dispatch) => _getMyBuysRequest(page, limit, dispatch);
const _getMyBuysRequest = async (page: number = 1, limit: number = 20, dispatch: Dispatch) => {
    try {
        dispatch({ type: getMyBuysRequestReducer })

        const response = await axiosClient.get(`${api.transactions}?type=purchase&page=${page}&limit=${limit}`);

        dispatch({ 
            type: getMyBuysSuccess, 
            payload: { 
                buys: response?.data.data.transactions, 
                total: response?.data.data.total 
            } 
        });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getMyBuysFailure });
    }
};

export const cleanMyBuys = () => (dispatch: Dispatch) => _cleanMyBuys(dispatch);
const _cleanMyBuys = (dispatch: Dispatch) => dispatch({ type: cleanMyBuysReducer });

export const getMyBuy = (id: string) => (dispatch: Dispatch) => _getMyBuy(id, dispatch);
const _getMyBuy = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getMyBuyRequest })

        const response = await axiosClient.get(`${api.transactions}/${id}`);

        dispatch({ type: getMyBuySuccess, payload: response?.data.data.transaction })
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getMyBuyFailure, payload: error?.error?.code });
    }
};

export const cleanMyBuy = () => (dispatch: Dispatch) => _cleanMyBuy(dispatch);
const _cleanMyBuy = (dispatch: Dispatch) => dispatch({ type: cleanMyBuyReducer });

export const getRegions = () => (dispatch: Dispatch) => _getRegions(dispatch);
const _getRegions = async (dispatch: Dispatch) => {
    try {
        dispatch({ type: getRegionsRequest });

        const response = await axiosClient.get(`${api.users}/regions`);

        dispatch({ type: getRegionsSuccess, payload: response?.data.data.company });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getRegionsFailure });
    }
}

export const getAllCities = () => (dispatch: Dispatch) => _getAllCities(dispatch);
const _getAllCities = async (dispatch: Dispatch) => {
    try {
        dispatch({ type: getCitiesRequest });

        const response = await axiosClient.get(`${api.users}/cities/`);

        dispatch({ type: getCitiesSuccess, payload: response?.data.data.company });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getCitiesFailure });
    }
}

export const getCities = (region_id: number) => (dispatch: Dispatch) => _getCities(region_id, dispatch);
const _getCities = async (region_id: number, dispatch: Dispatch) => {
    try {
        dispatch({ type: getCitiesRequest });

        const response = await axiosClient.get(`${api.users}/cities/region/${region_id}`);

        dispatch({ type: getCitiesSuccess, payload: response?.data.data.city });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getCitiesFailure });
    }
}

export const getAllCommunes = () => (dispatch: Dispatch) => _getAllCommunes(dispatch);
const _getAllCommunes = async (dispatch: Dispatch) => {
    try {
        dispatch({ type: getCommunesRequest });

        const response = await axiosClient.get(`${api.users}/communes`);

        dispatch({ type: getCommunesSuccess, payload: response?.data.data.company });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getCommunesFailure });
    }
}

export const getCommunes = (city_id: number) => (dispatch: Dispatch) => _getCommunes(city_id, dispatch);
const _getCommunes = async (city_id: number, dispatch: Dispatch) => {
    try {
        dispatch({ type: getCommunesRequest });

        const response = await axiosClient.get(`${api.users}/communes/city/${city_id}`);

        dispatch({ type: getCommunesSuccess, payload: response?.data.data.company });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getCommunesFailure });
    }
}

export const patchResidence = (formData: ResidenceProps) => (dispatch: Dispatch, getState: GetState) => _patchResidence(formData, dispatch, getState);
const _patchResidence = async (formData: ResidenceProps, dispatch: Dispatch, getState: GetState) => {
    try {
        dispatch({ type: patchResidenceRequest });

        const userId = getState().auth.user?._id;
        const body = JSON.stringify({ residence: formData });
        
        const response = await axiosClient.patch(`${api.users}/${userId}`, body);

        dispatch({ type: patchResidenceSuccess, payload: response?.data.data.user});
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: patchResidenceFailure });
    }
}

export const cleanProfileFetching = () => (dispatch: Dispatch) => _cleanProfileFetching(dispatch);
const _cleanProfileFetching = (dispatch: Dispatch) => {
    dispatch({ type: cleanEditUser });
    dispatch({ type: cleanResidenceFetching });
};

export const getFavorites = (page: number = 1, limit: number = 20) => (dispatch: Dispatch) => _getFavorites(page, limit, dispatch);
const _getFavorites = async (page: number = 1, limit: number = 20, dispatch: Dispatch) => {
    try {
        dispatch({ type: getFavoritesRequest })

        const response = await axiosClient.get(`${api.publications}/likes?page=${page}&limit=${limit}`);

        const { data: { liked_publications } } = response?.data;

        const likedPublications = liked_publications.map((publication: any) => ({ ...publication, liked: true }));
        dispatch({ 
            type: getFavoritesSuccess, 
            payload: {
                favorites: likedPublications,
                total: response?.data?.total
            }
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getFavoritesFailure, payload: errorResponse })
    }
}

export const cleanFavorites = () => (dispatch: Dispatch) => _cleanFavorites(dispatch);
const _cleanFavorites = (dispatch: Dispatch) => dispatch({ type: cleanFavoritesReducer });

export const setFavorites = (data: any) => (dispatch: Dispatch) => _setFavorites(data, dispatch);
const _setFavorites = (data: any, dispatch: Dispatch) => dispatch({ type: setFavoritesReducer, payload: data });

export const likePublication = (id: string) => (dispatch: Dispatch) => _likePublication(id, dispatch);
const _likePublication = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: likePublicationRequest });

        await axiosClient.post(`${api.publications}/${id}/likes`);

        dispatch({ type: likePublicationSuccess })
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: likePublicationFailure })
    }
};

export const dislikePublication = (id: string) => (dispatch: Dispatch) => _dislikePublication(id, dispatch);
const _dislikePublication = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: dislikePublicationRequest });

        await axiosClient.delete(`${api.publications}/${id}/likes`);

        dispatch({ type: dislikePublicationSuccess });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: dislikePublicationFailure });
    }
};

export const cleanLikedPublication = () => (dispatch: Dispatch) => _cleanLikedPublication(dispatch);
const _cleanLikedPublication = (dispatch: Dispatch) => dispatch({ type: cleanLikePublicationReducer });

export const getCreditData = (id: string) => (dispatch: Dispatch) => _getCreditData(id, dispatch);
const _getCreditData = async (id: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getCreditDataRequest });

        console.log("Credit data", id)

        const response = await axiosClient.get(`${api.credits}/credit-requests/transaction/${id}`);

        console.log("Credit data", response?.data)

        dispatch({ type: getCreditDataSuccess, payload: response?.data.data.credit_requests });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getCreditDataFailure, payload: errorResponse?.code });
    }
};

export const cleanGetCreditData = () => (dispatch: Dispatch) => _cleanGetCreditData(dispatch);
const _cleanGetCreditData = (dispatch: Dispatch) => dispatch({ type: cleanGetCreditDataReducer });

export const getPayments = (idTransaction: string) => (dispatch: Dispatch) => _getPayments(idTransaction, dispatch);
const _getPayments = async (idTransaction: string, dispatch: Dispatch) => {
    try {
        dispatch({ type: getPaymentsRequest });

        console.log("payments", idTransaction)

        const response = await axiosClient.get(`${api.payments}/transaction/${idTransaction}`);

        dispatch({ type: getPaymentsSuccess, payload: response?.data.data.payments });
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: getPaymentsFailure, payload: errorResponse?.code });
    }
};

export const cleanGetPayments = () => (dispatch: Dispatch) => _cleanGetPayments(dispatch);
const _cleanGetPayments = (dispatch: Dispatch) => dispatch({ type: cleanGetPaymentsReducer });

export const postPaymentDocument = (formData: any) => (dispatch: Dispatch, getState: GetState) => _postPaymentDocument(formData, dispatch, getState);
export const _postPaymentDocument = async (formData: any, dispatch: Dispatch, getState: GetState) => {
    try {
        dispatch({ type: postPaymentDocumentRequest });
        const token = getState().auth.token;
        const paymentCode = getState().profile.payments?.data[0]?.code;

        console.log("paymentCode", paymentCode)

        const response = await fetch(process.env.REACT_APP_API_PAYMENTS_FILES || "", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        });
        
        const json = await response.json();
        if (!json.success) throw json;

        dispatch({ 
            type: postPaymentDocumentSuccess, 
            payload: {
                url: json.data.file.path,
                code: paymentCode,
                payment_method: "vale-vista"
            } 
        });

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({ type: postPaymentDocumentFailure, payload: errorResponse?.code });
    }
};

export const cleanPostPaymentDocument = () => (dispatch: Dispatch) => _cleanPostPaymentDocument(dispatch);
const _cleanPostPaymentDocument = (dispatch: Dispatch) => dispatch({ type: cleanPostPaymentDocumentReducer });

export const postPayment = (documentData: any, id: string) => (dispatch: Dispatch) => _postPayment(documentData, id, dispatch)
const _postPayment = async ( documentData: any, id: string, dispatch: Dispatch) => {
  try {
    dispatch({ type: postPaymentRequest });

    const body = JSON.stringify(documentData);

    const response = await axiosClient.post(`${api.payments}/payment-processes/${id}`, body);

    dispatch({ type: postPaymentSuccess, payload: response?.data.data.payment_process});
  } catch (error: any) {
    const errorResponse = error?.response?.data?.error;
    console.log(errorResponse);
    dispatch({ type: postPaymentFailure, payload: errorResponse?.code });
  }
};

export const cleanPostPayment = () => (dispatch: Dispatch) => _cleanPostPayment(dispatch);
const _cleanPostPayment = (dispatch: Dispatch) => dispatch({ type: cleanPostPaymentReducer });

export const setProfilePublication = (publication: any) => (dispatch: Dispatch) => _setProfilePublication(publication, dispatch);
const _setProfilePublication = (publication: any, dispatch: Dispatch) => dispatch({type: setPublication, payload: publication});

export const getMyOffers = (type: string, page?: string, limit?: string) => (dispatch: Dispatch) => _getMyOffers(type, page, limit, dispatch)
const _getMyOffers = async (type: string, page: string = "1", limit: string = "20", dispatch: Dispatch) => {
  try {
    dispatch({ type: getMyOffersRequest });

    const response = await axiosClient.get(`${api.publications}/with-offers?offer=${type}&page=${page}&limit=${limit}`);

    dispatch({ type: getMyOffersSuccess, payload: response?.data.data?.publications});
  } catch (error: any) {
    const errorResponse = error?.response?.data?.error;
    console.log(errorResponse);
    dispatch({ type: getMyOffersFailure, payload: errorResponse?.code });
  }
};

export const cleanGetMyOffers = () => (dispatch: Dispatch) => _cleanGetMyOffers(dispatch);
const _cleanGetMyOffers = (dispatch: Dispatch) => dispatch({ type: cleanGetMyOffersReducer });

export const cleanSettings = () => (dispatch: Dispatch) => _cleanSettings(dispatch);
const _cleanSettings = (dispatch: Dispatch) => dispatch({ type: cleanSettingsReducer });

export const changePassword = (password: string) => (dispatch: Dispatch, getState: GetState) => _changePassword(password, dispatch, getState);
const _changePassword = async (password: string, dispatch: Dispatch, getState: GetState) => {
    try {
        dispatch({ type: changePasswordRequest });
        await axiosClient.post(`${api.users}/change-password`, {
            password: password
        });
        dispatch({ type: changePasswordSuccess });

    } catch (error) {
        dispatch({ type: changePasswordFailure });
        console.log("err", error)
    }
}