import { memo, useEffect, useState } from 'react';
import styles from './creditRequest.module.scss';
import { useNavigate } from "react-router-dom";
import { CreditRequestProps } from '../../../interfaces/pages';
import BuyModal from '../../../components/BuyModal';
import Button from '../../../components/Button';
import ToggleButtonShared from '../../../components/ToggleButton/ToggleButton';
import TextFieldShared from '../../../components/TextField/TextField';
import { optionsRevenue, optionsContract, optionsSeniority } from '../../../constants/Buy';

const CreditRequest = ({
    setStep,
    step,
    method,
    publication,
    setCleanAll,
    setCreditRequest,
    creditRequestReducer
}: CreditRequestProps) => {
    const navigate = useNavigate();
    const [liquidRent, setLiquidRent] = useState<number>(0);
    const [senioritySelected, setSenioritySelected] = useState<number>(0);
    const [revenueSelected, setRevenueSelected] = useState<string>("dependent");
    const [contractSelected, setContractSelected] = useState<string>("fixedTerm");
    const [disabled, setDisabled] = useState<boolean>(true);

    const handlePressBack = () => {
        setCleanAll();
        navigate(`/publication/${publication?.data?._id}`);
    }

    const handleNextStep = () => {
        setCreditRequest(
            liquidRent, 
            revenueSelected,
            contractSelected,
            senioritySelected
        );
        setStep(step + 1);
        navigate("/buy/financing");
    }

    useEffect(() => {
        if(liquidRent !== 0){
            setDisabled(false);
        }else{
            setDisabled(true);
        }
    }, [liquidRent]);

    useEffect(() => {
        document.body.style.overflowY = "auto";
        if(creditRequestReducer?.liquidRent !== 0){
            setLiquidRent(creditRequestReducer?.liquidRent);
        }
        setRevenueSelected(creditRequestReducer?.revenue);
        setContractSelected(creditRequestReducer?.contract);
        setSenioritySelected(creditRequestReducer?.seniority);
    }, [creditRequestReducer]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <BuyModal title="Solicitud de crédito" onBack={handlePressBack} step={step} method={method}>
            <div className={styles.creditRequest}>
                <div className={`${styles.item} ${styles.liquidRent}`}>
                    <p className={styles.title}>¿Cuál es tu renta líquida?</p>
                    <div className={styles.content}>
                        <TextFieldShared
                            variant="borderless"
                            name="liquidRent"
                            value={liquidRent}
                            onChange={(value: any) => setLiquidRent(value)}
                            autoFocus={true}
                            className={styles.input}
                        />
                    </div>
                    <p className={styles.subtitle}>*Recomendamos renta líquida mayor o igual a $700.000</p>
                </div>
                <div className={`${styles.item} ${styles.revenues}`}>
                    <p className={styles.title}>¿Cómo recibes ingresos?</p>
                    <div className={styles.content}>
                        <ToggleButtonShared 
                            type="group"
                            value={revenueSelected}
                            onPress={(value: any) => setRevenueSelected(value)}
                            exclusive
                        >
                            {optionsRevenue}
                        </ToggleButtonShared>
                    </div>
                </div>
                <div className={`${styles.item} ${styles.contract}`}>
                    <p className={styles.title}>¿Qué tipo de contrato tienes?</p>
                    <div className={styles.content}>
                        <ToggleButtonShared 
                            type="group"
                            value={contractSelected}
                            onPress={(value: any) => setContractSelected(value)}
                            exclusive
                        >
                            {optionsContract}
                        </ToggleButtonShared>
                    </div>
                </div>
                <div className={`${styles.item} ${styles.seniority}`}>
                    <p className={styles.title}>¿Cuál es la antiguedad laboral?</p>
                    <p className={styles.subtitle}>(Número de cotizaciones consecutivas)</p>
                    <div className={styles.content}>
                        {
                            optionsSeniority && optionsSeniority.map((option: any, index: number) => (
                                <ToggleButtonShared 
                                    key={index}
                                    type="single"
                                    value={option?.value}
                                    onPress={(value: any) => setSenioritySelected(value)}
                                    className={styles.option}
                                    selected={(senioritySelected === option?.value) ? true : false}
                                >
                                    <p>
                                        <span>{option?.label?.value}</span>
                                        <span>{option?.label?.time}</span>
                                    </p>
                                </ToggleButtonShared>
                            ))
                        }
                    </div>
                </div>
            </div>
            <Button
                onPress={handleNextStep}
                title="Siguiente"
                primary
                disabled={disabled}
                className={styles.submitButton}
            />
        </BuyModal>
    )
}

export default memo(CreditRequest);