import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from "../reducers/auth";
import notificationsReducer from "../reducers/notifications";
import profileReducer from "../reducers/profile";
import documentsReducer from "../reducers/documents";
import buyReducer from "../reducers/buy";
import publicationReducer from "../reducers/publication";
import publicationsReducer from "../reducers/publications";
import banksReducer from "../reducers/banks";
import salePostReducer from "../reducers/salePost";
import schedulingReducer from "../reducers/scheduling";
import transactionReducer from "../reducers/transaction";
import termsReducer from "../reducers/terms";
import discountReducer from "../reducers/discount";
import inspectionReducer from "../reducers/inspection";
import userMiddleware from "./middlewares/userMiddleware";
import { dev } from "./api";

const middlewares = dev ? [thunk, logger, userMiddleware] : [thunk, userMiddleware];

const persistAuth = {
    key: 'auth',
    storage: storage,
};

const persistBuy = {
    key: 'buy',
    storage: storage,
};

const persistedAuthReducer = persistReducer(persistAuth, authReducer);
const persistedBuyReducer = persistReducer(persistBuy, buyReducer);

export const store = configureStore({
    reducer: {
        auth: persistedAuthReducer,
        notifications: notificationsReducer,
        profile: profileReducer,
        documents: documentsReducer,
        buy: persistedBuyReducer,
        publication: publicationReducer,
        publications: publicationsReducer,
        banks: banksReducer,
        salePost: salePostReducer,
        scheduling: schedulingReducer,
        transaction: transactionReducer,
        terms: termsReducer,
        discount: discountReducer,
        inspection: inspectionReducer
    },
    middleware: middlewares,
});

export const persistor = persistStore(store);
export type StoreState = typeof store;
export type PersitorStoreState = typeof persistor;
export type GetState = typeof store.getState;