export const optionsRevenue = [
    {
        label: "dependiente",
        value: "dependent"
    },
    {
        label: "independiente",
        value: "independent"
    },
];

export const optionsContract = [
    {
        label: "plazo fijo",
        value: "fixedTerm"
    },
    {
        label: "indefinido",
        value: "undefined"
    }
];

export const optionsSeniority = [
    {
        label: {
            value: "0-6",
            time: "meses"
        },
        value: 0
    },
    {
        label: {
            value: "6-12",
            time: "meses"
        },
        value: 1
    },
    {
        label: {
            value: "1-2",
            time: "años"
        },
        value: 2
    },
    {
        label: {
            value: "+2",
            time: "años"
        },
        value: 3
    },
];

export const optionsCreditType = [
    {
        label: "inteligente",
        value: "intelligent"
    },
    {
        label: "convencional",
        value: "conventional"
    },
];

export const optionsFees = [
    {
        label: {
            value: "12",
            text: "meses"
        },
        value: 12
    },
    {
        label: {
            value: "24",
            text: "meses"
        },
        value: 24
    },
    {
        label: {
            value: "36",
            text: "meses"
        },
        value: 36
    },
    {
        label: {
            value: "48",
            text: "meses"
        },
        value: 48
    }
];