import React from "react";
import { RoutesProps } from "../interfaces";
import { Routes as ReactRoutes, Route } from "react-router-dom";
import ProtectedRoute from "../containers/ProtectedRouteContainer";
import { RouteObject } from "../interfaces/navigation";
import { searchRoutes } from "../pages/Search/routes";
import { publicationRoutes } from "../pages/Publication/routes";
import { buyRoutes } from "../pages/Buy/routes";
import { frequentQuestionRoutes } from "../pages/FrequentQuestions/routes";
import { homeRoutes } from "../pages/Home/routes";
import { loginRoutes } from "../pages/Login/routes";
import { registerRoutes } from "../pages/Register/routes";
import { profileRoutes } from "../pages/Profile/routes";
import { sellRoutes } from "../pages/Sell/routes";
import { termsRoutes } from "../pages/Terms/routes";
import { linzeAiRoutes } from "../pages/LinzeAi/routes";
import { notFoundPageRoutes } from "../pages/NotFoundPage/routes";
import { downloadRoutes } from "../pages/Download/routes";
import { deleteAccountRoutes } from "../pages/DeleteAccount/routes";
import { notificationsRoutes } from "../pages/Notifications/routes";
import SuspenseLoader from "../components/SuspenseLoader";
import { useIsMobileDevice } from "../helpers/hooks";
import { myOffersRoutes } from "../pages/Profile/MyOffers/routes";
import { policiesRoutes } from "../pages/Policies/routes";
import { gettingStartedRoutes } from "../pages/GettingStarted/routes";
import { linkRoutes } from "../pages/Linking/routes";

const Routes = ({ fetchingRefreshToken, refreshType }: RoutesProps) => {
    const isMobileDevice = useIsMobileDevice();

    const allRoutes: RouteObject[] = [
        ...homeRoutes,
        ...searchRoutes,
        ...publicationRoutes,
        ...myOffersRoutes,
        ...loginRoutes,
        ...buyRoutes,
        ...registerRoutes,
        ...sellRoutes,
        ...frequentQuestionRoutes,
        ...profileRoutes,
        ...termsRoutes,
        ...linzeAiRoutes,
        ...notificationsRoutes,
        ...downloadRoutes,
        ...deleteAccountRoutes,
        ...notFoundPageRoutes,
        ...policiesRoutes,
        ...gettingStartedRoutes,
        ...linkRoutes
    ];
    

    return (
        <ReactRoutes>
            {allRoutes.map((route) => {
                if(!isMobileDevice && route.onlyMobile) return;
                return <Route
                    key={route.path}
                    path={route.path}
                    element={
                        <React.Suspense fallback={
                            <SuspenseLoader />
                        }>
                            {
                                (fetchingRefreshToken === "loading" && refreshType === "first_initialization")
                                ?   <SuspenseLoader />
                                :   route.protected 
                                    ?   <ProtectedRoute>{route.component}</ProtectedRoute>
                                    :   route.component
                            }
                        </React.Suspense>
                    }
                >
                    {route?.subRoutes?.map((subRoute, index) => {
                        return <Route
                            path={subRoute.path}
                            key={"subRoute-" + index}
                            element={subRoute.protected ?
                                <ProtectedRoute>{subRoute.component}</ProtectedRoute>
                                :
                                subRoute.component
                            }>
                            {subRoute?.subRoutes?.map((subSubRoute, index) => (
                                <Route
                                    path={subSubRoute.path}
                                    key={"subSubRoute-" + index}
                                    element={subSubRoute.component}
                                > 
                                {
                                    subSubRoute?.subRoutes?.map((subSubSubRoute) => (
                                        <Route
                                        path={subSubSubRoute.path}
                                        key={"subSubSubRoute-" + index}
                                        element={subSubSubRoute.component}
                                    /> 
                                    ))
                                }
                                </Route>
                                ))}
                        </Route>
                    })}
                </Route>
            })}
             <Route 
                path='*' 
                element={
                    <React.Suspense fallback={
                        <SuspenseLoader />
                    }>
                        {notFoundPageRoutes[0].component}
                    </React.Suspense>
                } 
            />  
        </ReactRoutes>
    )
};

export default Routes;