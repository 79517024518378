import { memo, useMemo } from 'react';
import styles from './publicationDetail.module.scss';
import { PublicationDetailProps } from '../../interfaces/components';
import { Markup } from 'interweave';
import { addPointsInText, boldBetweenAsterisks, capitalize } from '../../helpers/app';
import Slider from '../Slider';
import Card from '../../containers/Card/CardContainer';
import Favorite from '../../containers/Favorite/FavoriteContainer';
import ShareButton from '../ShareButton';
import InformationLoader from './Loader/Information/InformationLoader';
import VehicleSimilarsLoader from './Loader/VehicleSimilars/VehicleSimilarsLoader';
import { images } from '../../constants/images';
import { Divider, Skeleton } from '@mui/material';
import DescriptionLoader from './Loader/Description/DescriptionLoader';
import DetailLoader from './Loader/Detail/DetailLoader';

const PublicationDetail = ({
    type = "publication",
    publication,
    loading = false,
    clickFavorite = () => {},
    publications,
    children
}: PublicationDetailProps) => {
    const isBonus = useMemo(() => {
        return false
    }, [publication]);

    return (
        <div className={`${styles.container} ${type === "myPublication" && styles.myPublication}`}>
            {
                <>
                    <div className={styles.grid}>
                        <div className={styles.slider}>
                            {
                                <Slider
                                    type="thumbs"
                                    options={
                                        publication?.pictures && publication?.pictures.length > 0
                                        ?
                                            publication?.pictures.map((item: any, index: number) => (
                                                <img loading="lazy" key={index} src={item?.path ?? item?.previewURL ?? item?.uri ?? item?.url} className={publication?.paused && styles.paused} alt={`${publication?.title} ${publication.version ?? ""} año ${publication.year}`} />
                                            ))
                                        :
                                            [1].map((_: any, index: number) => (
                                                <img loading="lazy" key={index} src={images.noImagePublication} alt="No image found publication linzecar" />
                                            ))
                                    }
                                    pagination
                                    loading={loading && (!Array.isArray(publication?.pictures) && !publication?.pictures?.length)}
                                />
                            }
                        </div>
                        {
                            (loading && !publication.id)
                            ?   <div className={`${styles.information} ${loading && styles.loader}`}>
                                    <InformationLoader />
                                </div>
                            :   <div className={`${styles.information} ${(publication?.certified || publication?.financingBonus) && styles.notPaddingBottom}`}>
                                    <p className={styles.description}>{publication?.year} | {addPointsInText(publication?.mileage)} km</p>
                                    <h2 className={styles.title}>{publication?.title}</h2>
                                    {
                                        publication?.version &&
                                        <h3 className={styles.version}>{publication?.version}</h3>
                                    }
                                    <p className={styles.price}>$ {addPointsInText(publication?.price)}</p>
                                    {
                                        publication?.sold 
                                        ?   <h3 className={styles.sold}>Vendido</h3>
                                        :   <div className={styles.children}>{children}</div>
                                    }
                                    {
                                        type !== "salePost" && window.innerWidth > 1024 &&
                                        <>
                                            <ShareButton 
                                                path={`/publication/${publication?.id}`}
                                                className={styles.share} 
                                            />
                                            <Favorite
                                                id={publication?.id}
                                                liked={publication?.liked}
                                                className={styles.favorite}
                                                onPress={() => clickFavorite(publication)}
                                                redirect={`/publication/${publication?.id}`}
                                            />
                                        </>
                                    }
                                </div>
                        }
                        {
                            !loading && (publication.certified || isBonus) &&
                            <div className={styles.certifiedFinancing}>
                                {
                                    publication.certified && 
                                    <div className={`${styles.item} ${styles.certified}`}>
                                        <p>Vehículo verificado</p>
                                        <img src={images.check_blue} alt="vehículo verificado" />
                                    </div>
                                }
                                {
                                    isBonus && 
                                    <div className={`${styles.item} ${styles.financingBonus}`}>
                                        <img src={images.financingBonus} alt="bono con financiamiento" />
                                        <p>Bono con financiamiento</p>
                                    </div>
                                }
                            </div>
                        }
                        {
                            window.innerWidth <= 1024 &&
                            <div className={styles.responsiveInformation}>
                                {
                                    type !== "salePost" && 
                                    <div className={styles.buttons}>
                                        <Favorite
                                            id={publication?.id}
                                            liked={publication?.liked}
                                            className={styles.favorite}
                                            onPress={() => clickFavorite(publication)}
                                            redirect={`/publication/${publication?.id}`}
                                        />
                                        <ShareButton className={styles.share} />
                                    </div>
                                }
                                <p className={styles.price}>$ {addPointsInText(publication?.price)}</p>
                                <Divider className={styles.divider} />
                                {
                                    publication?.sold 
                                    ?   <h3 className={styles.sold}>Vendido</h3>
                                    :   children
                                }
                                <Divider className={styles.divider} />
                            </div>
                        }
                        {
                            loading
                                ?   <div className={`${styles.description} ${window.innerWidth > 1024 && styles.border}`}>
                                        <DescriptionLoader />
                                    </div>
                                :   publication?.chatgpt_description &&
                                    <div className={`${styles.description} ${window.innerWidth > 1024 && styles.border}`}>
                                        <p className={styles.title}>Descripción</p>
                                        <p className={styles.content}>{publication?.chatgpt_description}</p>
                                    </div>
                                
                        }
                        {
                            loading
                                ?   <ul className={styles.detail}>
                                        <DetailLoader />
                                    </ul>
                                :   (publication?.detail && Array.isArray(publication?.detail)) &&
                                    <ul className={styles.detail}>
                                        {
                                            publication?.detail?.map((item: any) => {
                                                return (
                                                    item?.key !== "bonus" 
                                                    ? 
                                                        <li>
                                                            <img src={item?.icon} alt={item?.value} />
                                                            <Markup content={boldBetweenAsterisks(item?.value)} />
                                                        </li>
                                                    :
                                                        null
                                                )
                                            })
                                        }
                                    </ul>
                        }
                        <div className={styles.table}>
                            <div className={styles.comparaContainer}>
                            <p className={styles.title}>Características</p>
                            <div className={styles.comparaButton} onClick={() => window.open('https://seguro-auto.comparaonline.cl/form/step/vehicle?carDealerName=linze&carDealerAgentId=&noPrefill=', '_blank')}>
                                <span className={styles.comparaTitle}>Asegura tu vehículo con</span>
                                <img src={`${images.comparaLogo}`} className={styles.comparaImg}/>
                            </div>
                            </div>
                            <table>
                                <body>
                                    {
                                        window.innerWidth <= 1024 && publication?.caracteristics ? publication?.caracteristics.map((item: any, index: number) => (
                                            <tr className={styles.item}>
                                                <td className={styles.icon}>
                                                    <img src={`${item?.icon}`} alt={item?.name} />
                                                </td>
                                                <th className={styles.name}>{item?.name}</th>
                                                <td className={styles.value}>
                                                    {
                                                        loading 
                                                        ? <Skeleton animation="wave" variant="text" className={styles.skeleton} /> 
                                                        : item?.value ?? "-"
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        publication?.caracteristics && publication?.caracteristics.map((item: any, index: number) => {
                                            if(index > 3) return null;
                                            return(
                                            <tr className={styles.item}>
                                                <td className={styles.icon}>
                                                    <img src={`${item?.icon}`} alt={item?.name} />
                                                </td>
                                                <th className={styles.name}>{item?.name}</th>
                                                <td className={styles.value}>
                                                    {
                                                        loading 
                                                        ? <Skeleton animation="wave" variant="text" className={styles.skeleton} /> 
                                                        : item?.value ?? "-"
                                                    }
                                                </td>
                                                <td className={styles.icon}>
                                                    <img src={`${publication?.caracteristics[index+4]?.icon}`} alt={publication?.caracteristics[index+4]?.name} />
                                                </td>
                                                <th className={styles.name}>{publication?.caracteristics[index+4]?.name}</th>
                                                <td className={styles.value}>
                                                    {
                                                        loading 
                                                        ? <Skeleton animation="wave" variant="text" className={styles.skeleton} /> 
                                                        : publication?.caracteristics[index+4]?.value ?? "-"
                                                    }
                                                </td>
                                            </tr>
                                        )})        
                                    }
                                </body>
                            </table>
                        </div>
                    </div>
                    {
                        type === "publication"
                        ?
                            loading
                                ?   <div className={`${styles.item} ${styles.similars}`}>
                                        <VehicleSimilarsLoader />
                                    </div>
                                : publications && publications.length > 0
                                    ? <div className={`${styles.item} ${styles.similars} ${publications.length < 4 && styles.noArrows}`}>
                                        <h2 className={styles.title}>Vehículos similares</h2>
                                        <div className={styles.content}>
                                            <Slider
                                                type="normal"
                                                perView="auto"
                                                perGroup={2}
                                                limit={4}
                                                spaceBetween={24}
                                                options={
                                                    publications && publications.map((item: any, index: number) => (
                                                        <Card
                                                            key={index}
                                                            navigation={`/publication/${item._id}`}
                                                            item={item}
                                                            pressFavorite={() => clickFavorite(item)}
                                                            redirect={`/publication/${item?._id}`}
                                                        />
                                                    ))
                                                }
                                            />
                                        </div>
                                    </div>
                                    :
                                    null
                        :
                            null
                    }
                </>
            }
        </div>
    )
}

export default memo(PublicationDetail);