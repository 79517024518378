import { useEffect, useState } from "react";
import styles from "./description.module.scss";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import SellModal from "../../../components/SellModal/SellModal";
import Tag from "../../../components/Tag";
import TextField from "../../../components/TextField";
import { capitalize } from "../../../helpers/app";
import { DescriptionProps } from "../../../interfaces/salePost";
import { BsPlus } from 'react-icons/bs';
import { useToast } from "../../../helpers/hooks";

const Description = ({
    tags,
    brands,
    models,
    color,
    setTags,
    plate,
    isFoundPlate,
    description,
    setDescription,
}: DescriptionProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [userDescription, setUserDescription] = useState<any>({ value: "", error: "", disabledAdd: false })

    const onAddDescription = (value: any) => {
        setUserDescription({ ...userDescription, value: value })
    }

    useEffect(() => {
        if (!plate) return navigate("/sell");
    }, [plate]);

    return (
        <SellModal
            step={isFoundPlate ? 5 : 7}
            title="Descripción"
            subTitle="Ingresa aquí un breve comentario a cerca de tu vehículo."
            onClickBack={() => navigate("/sell/records")}
            isFoundPlate={isFoundPlate}
        >
            <div className={styles.inputsContainer}>
                <div className={styles.inputContent}>
                    <TextField
                        label="Describe el vehículo"
                        name="Describe el vehículo"
                        value={userDescription.value}
                        onChange={onAddDescription}
                        className={styles.input}
                        // error={inputTags?.error}
                        multiline={true}
                    />
                </div>
            </div>
            <Button
                onPress={() => navigate("/sell/pictures")}
                title="Continuar"
                primary
                className={styles.submitButton}
            />
        </SellModal>
    )
};

export default Description;