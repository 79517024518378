import { Dispatch } from "@reduxjs/toolkit";
import { api, dev } from "../config/api";
import { GetState } from "../config/store";
import { 
    setDocuments as setDocumentsReducer,
    getDocumentsTypesRequest, 
    getDocumentsTypesSuccess, 
    getDocumentsTypesFailure, 
    cleanGetDocumentsTypes as cleanGetDocumentsTypesReducer,
    getDocumentsRequest, 
    getDocumentsSuccess, 
    getDocumentsFailure, 
    cleanGetDocuments as cleanGetDocumentsReducer, 
    getIsValidDocumentsRequest,
    getIsValidDocumentsSuccess,
    getIsValidDocumentsFailure,
    cleanIsValidDocuments as cleanIsValidDocumentsReducer,
    postDocumentImageRequest,
    postDocumentImageSuccess,
    postDocumentImageFailure,
    cleanPostDocumentImage as cleanPostDocumentImageReducer,
    postDocumentsRequest,
    postDocumentsSuccess,
    postDocumentsFailure,
    cleanPostDocuments as cleanPostDocumentsReducer,
    deleteDocumentImageRequest, 
    deleteDocumentImageSuccess, 
    deleteDocumentImageFailure, 
    cleanDeleteDocumentImage as cleanDeleteDocumentImageReducer,
    deleteDocumentRequest, 
    deleteDocumentSuccess, 
    deleteDocumentFailure, 
    cleanDeleteDocument as cleanDeleteDocumentReducer
} from "../reducers/documents";
import { DocumentsTypes } from "../interfaces/types";
import { axiosClient } from "../config/axios";

export const setDocuments = (type: any, documents: any[]) => (dispatch: Dispatch) => _setDocuments(type, documents, dispatch);
const _setDocuments = (type: any, documents: any[], dispatch: Dispatch) => dispatch({ type: setDocumentsReducer, payload: {type: type, data: documents } });

export const getDocumentsTypes = () => (dispatch: Dispatch) => _getDocumentsTypes(dispatch);
const _getDocumentsTypes = async (dispatch: Dispatch) => {
    try {
        dispatch({type: getDocumentsTypesRequest});

        const response = await axiosClient.get(`${api.users}/document-types`);

        dispatch({
            type: getDocumentsTypesSuccess, 
            payload: {
                documents: response?.data?.data.document_types
            }}
        );
    }catch(error: any){
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: getDocumentsTypesFailure});
    }
}

export const cleanGetDocumentsTypes = () => (dispatch: Dispatch) => _cleanGetDocumentsTypes(dispatch);
const _cleanGetDocumentsTypes = (dispatch: Dispatch) => dispatch({ type: cleanGetDocumentsTypesReducer });

export const getDocuments = (type: any) => (dispatch: Dispatch) => _getDocuments(type, dispatch);
const _getDocuments = async (type: any, dispatch: Dispatch) => {
    try {
        dispatch({type: getDocumentsRequest});

        const response = await axiosClient.get(`${api.users}/documents?document_type=${type._id}`);

        dispatch({
            type: getDocumentsSuccess, 
            payload: {
                type: type.code, 
                data: response?.data?.data.documents 
            }
        });
    }catch(error: any){
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: getDocumentsFailure});
    }
}

export const cleanGetDocuments = () => (dispatch: Dispatch) => _cleanGetDocuments(dispatch);
const _cleanGetDocuments = (dispatch: Dispatch) => dispatch({ type: cleanGetDocumentsReducer });

export const getIsValidDocuments = () => (dispatch: Dispatch) => _getIsValidDocuments(dispatch);
export const _getIsValidDocuments = async (dispatch: Dispatch) => {
    try {
        dispatch({type: getIsValidDocumentsRequest});

        const response = await axiosClient.get(`${api.users}/documents/valid`);

        dispatch({type: getIsValidDocumentsSuccess, payload: response?.data?.data});
    }catch(error: any){
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: getIsValidDocumentsFailure, payload: errorResponse?.code});
    }
}

export const cleanIsValidDocuments = () => (dispatch: Dispatch) => _cleanIsValidDocuments(dispatch);
const _cleanIsValidDocuments = (dispatch: Dispatch) => dispatch({ type: cleanIsValidDocumentsReducer });

export const postDocumentImage = (formData: any, id: number, type: DocumentsTypes) => (dispatch: Dispatch, getState: GetState) => _postDocumentImage(formData, id, type, dispatch, getState);
export const _postDocumentImage = async (formData: any, id: number, type: DocumentsTypes, dispatch: Dispatch, getState: GetState) => {
    try {
        dispatch({ type: postDocumentImageRequest })
        const token = getState().auth.token;
        const types = getState().documents.types.data;
        const currentType: any = types.find((item: any) => item.code === type);

        const response = await fetch(process.env.REACT_APP_API_DOCUMENTS_FILES || "", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        });
        
        const json = await response.json();
        if (!json.success) throw json;

        const documents = getState().documents.documents[type];
        const newDocuments = documents && documents.map((document: any) => {
            if(document.name === json?.data?.file?.original){
                return {
                    id: json.data.file.id,
                    url: json.data.file.path, 
                    name: document.name, 
                    document_type: currentType?._id,
                    upload: "success"
                } 
             }
             return document
        });
        
        dispatch({ 
            type: postDocumentImageSuccess, 
            payload: {
                type: type,
                data: newDocuments
            } 
        })

    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(error);
        const documents = getState().documents.documents[type];
        const newDocuments = documents && documents.map((document: any) => {
            if(document.id === id){
                return {
                    ...document,
                    upload: "failure"
                } 
            }
            return document;
        });
        dispatch({ 
            type: postDocumentImageFailure, 
            payload: {
                type: type,
                data: newDocuments
            } 
        })
    }
};

export const cleanPostDocumentImage = () => (dispatch: Dispatch) => _cleanPostDocumentImage(dispatch);
const _cleanPostDocumentImage = (dispatch: Dispatch) => dispatch({ type: cleanPostDocumentImageReducer });

export const postDocuments = (documentsData: any, type: DocumentsTypes) => (dispatch: Dispatch) => _postDocuments(documentsData, type, dispatch);
const _postDocuments = async (documentsData: any, type: DocumentsTypes, dispatch: Dispatch) => {
    try {
        dispatch({type: postDocumentsRequest });
        let documentsToUpload = documentsData.filter((document: any) => document.id);
        let documentsUploaded = documentsData.filter((document: any) => document._id);

        const documentsParsed = documentsToUpload.map((item: any) => (
            {
                document_type: item.document_type,
                url: item.url
            }
        ));

        const body = JSON.stringify({documents: documentsParsed})

        const response = await axiosClient.post(`${api.users}/documents/batch`, body);

        dispatch({
            type: postDocumentsSuccess, 
            payload: { 
                type: type, 
                data: documentsUploaded.concat(response?.data?.data.documents)
            }
        });
        
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: postDocumentsFailure});

    }
};

export const cleanPostDocuments = () => (dispatch: Dispatch) => _cleanPostDocuments(dispatch);
const _cleanPostDocuments = (dispatch: Dispatch) => dispatch({ type: cleanPostDocumentsReducer });

export const deleteDocumentImage = (id: string, type: DocumentsTypes) => (dispatch: Dispatch) => _deleteDocumentImage(id, type, dispatch);
export const _deleteDocumentImage = async (id: string, type: DocumentsTypes, dispatch: Dispatch) => {
    try {
        dispatch({type: deleteDocumentImageRequest});

        const response = await axiosClient.delete(`${api.users}/files/${id}`);

        dispatch({
            type: deleteDocumentImageSuccess, 
            payload: {
                type: type, 
                data: {_id: response?.data?.data.deleted_file}
            }
        });
        
    }catch(error: any){
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({
            type: deleteDocumentImageFailure, 
            payload: {
                type: type,
                data: id
            }
        });
    }
}

export const cleanDeleteDocumentImage = () => (dispatch: Dispatch) => _cleanDeleteDocumentImage(dispatch);
const _cleanDeleteDocumentImage = (dispatch: Dispatch) => dispatch({ type: cleanDeleteDocumentImageReducer });

export const deleteDocument = (id: string, type: DocumentsTypes) => (dispatch: Dispatch) => _deleteDocument(id, type, dispatch);
export const _deleteDocument = async (id: string, type: DocumentsTypes, dispatch: Dispatch) => {
    try {
        dispatch({type: deleteDocumentRequest});

        const response = await axiosClient.delete(`${api.users}/documents/${id}`);

        dispatch({
            type: deleteDocumentSuccess, 
            payload: {
                type: type, 
                data: response?.data?.data.document
            }
        });
        
    }catch(error: any){
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({
            type: deleteDocumentFailure, 
            payload: {
                type: type,
                data: id
            }
        });
    }
}

export const cleanDeleteDocument = () => (dispatch: Dispatch) => _cleanDeleteDocument(dispatch);
const _cleanDeleteDocument = (dispatch: Dispatch) => dispatch({ type: cleanDeleteDocumentReducer });