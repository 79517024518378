import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import NoDataList from "../../../../components/NoDataList";
import ProfileCard from "../../../../components/ProfileCard";
import { images } from "../../../../constants/images";
import { MyOffersProps } from "../../../../interfaces/components";
import styles from "./entryPoint.module.scss";
import { CircularProgress, Collapse, Divider, IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import { addPointsInText } from "../../../../helpers/app";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Pagination from "../../../../components/Pagination";
import moment from "moment";
import { translations } from "../../../../constants/app";
import OffersModal from "../../../../containers/Profile/MyPublications/Management/OffersModal/OffersModalContainer";

const EntryPoint = ({ 
    getMyOffers,
    myOffers,
    cleanGetMyOffers,
    offerReducer,
    counterOffers
 }: MyOffersProps) => {
    const params = useParams();
    const navigate = useNavigate();
    const [rowData, setRowData] = useState<any>({open: false, item: ""});
    const [page, setPage] = useState<number>(1);
    const [totalMyOffers, setTotalMyOffers] = useState<number>(1);
    const [section, setSection] = useState<string>("received");
    const [myOffersAux, setMyOffersAux] = useState<any>([]);
    const [offersModal, setOffersModal] = useState<any>({open: false, offer: {}});

    const onChangeSection = (value: string) => {
        setRowData({...rowData, open: false});
        setSection(value);
    }

    useEffect(() => {
        if(offerReducer?.put?.fetching === "success" || counterOffers?.put?.fetching === "success" || counterOffers?.post?.fetching === "success" || offerReducer?.put?.fetching === "failure" || counterOffers?.put?.fetching === "failure" || counterOffers?.post?.fetching === "failure"){
            getMyOffers(section, page);
        }
    }, [offerReducer?.put?.fetching, counterOffers?.put?.fetching, counterOffers?.post?.fetching]);

    useEffect(() => {
        if(myOffers?.fetching === "failure"){
            cleanGetMyOffers();
        }
        if(myOffers?.fetching === "success"){
            cleanGetMyOffers();
            setMyOffersAux(myOffers?.data);
            setTotalMyOffers(Math.ceil(100 / 20));
            if(rowData?.item){
                const offerFounded = myOffers?.data && myOffers?.data.find((item: any) => item?._id === rowData?.item?._id);
                const offerModalFounded = offerFounded && offerFounded.offers.find((item: any) => item?._id === offersModal?.offer?._id);
                setRowData({...rowData, item: offerFounded ?? {}});
                setOffersModal({...offersModal, offer: offerModalFounded ?? {}});
            }
        }
    }, [myOffers?.fetching]);

    useEffect(() => {
        getMyOffers(section, page);
    }, [page, section]);

    return (
        params.id 
        ? 
            <Outlet />
        :
            <>
                {
                    (myOffers?.fetching === "loading" && myOffersAux?.length === 0) 
                    ?
                        <div className={styles.loaderOffers} id="loaderOffers">
                            <CircularProgress 
                                size={styles.loader}
                                classes={{
                                    colorPrimary: styles.loader
                                }}
                            />
                            <p className={styles.text}>Cargando ofertas</p>
                        </div>
                        :
                            <ProfileCard>
                                <div className={styles.myOffersContainer}>
                                    <div className={styles.header}>
                                        <Tabs 
                                            className={styles.tabs} 
                                            value={section} 
                                            onChange={(event: React.SyntheticEvent, value: string) => onChangeSection(value)}
                                            TabIndicatorProps={{ className: styles.sideBarIndicator }}
                                            variant="fullWidth"
                                        >
                                            <Tab 
                                                value="received" 
                                                label="Recibidas" 
                                                className={styles.tab}
                                                classes={{
                                                    selected: styles.selected
                                                }}
                                            />
                                            <Tab 
                                                value="sent"
                                                label="Enviadas" 
                                                className={styles.tab}
                                                classes={{
                                                    selected: styles.selected
                                                }}
                                            />
                                        </Tabs>
                                    </div>
                                    <div id="myOfferCard" className={styles.myOffers}>
                                        {
                                            (myOffers?.fetching === "loading" && myOffersAux?.length > 0)
                                            ?
                                                <div className={styles.loaderOffers} id="loaderOffers">
                                                    <CircularProgress 
                                                        size={styles.loader}
                                                        classes={{
                                                            colorPrimary: styles.loader
                                                        }}
                                                    />
                                                    <p className={styles.text}>Cargando ofertas</p>
                                                </div>
                                            :
                                            myOffers?.data && myOffers?.data.length === 0
                                            ?
                                                <NoDataList
                                                    className={styles.noData}
                                                    title="No tienes ofertas aún"
                                                    text="Cuando hayas realizado o recibido una oferta de un vehículo podrás ver los detalles en esta sección."
                                                    image={images.noBuys}
                                                />
                                            :
                                                <TableContainer className={styles.table}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow className={styles.head}>
                                                                <TableCell className={styles.cell}>Foto</TableCell>
                                                                <TableCell className={styles.cell}>Vehiculo</TableCell>
                                                                <TableCell className={styles.cell}>Precio</TableCell>
                                                                <TableCell className={styles.cell}></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                myOffers?.data?.map((item: any, index: number) => {
                                                                    return (
                                                                    <>
                                                                        <TableRow className={styles.row} onClick={() => setRowData(rowData?.item?._id === item?._id ? {...rowData, open: !rowData?.open} : {item: item, open: rowData?.open ? rowData?.open : !rowData?.open})} key={index}>
                                                                            <TableCell className={styles.cell}>
                                                                                <div className={styles.picture}>
                                                                                    <img src={(item?.pictures && item?.pictures[0]?.path) ?? images.noImagePublication} alt="Oferta" />
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell className={`${styles.cell} ${styles.bold} ${styles.ellipsis}`}>
                                                                                <p className={`${styles.text} ${styles.vehicle}`}>{`${item?.title}`}</p>
                                                                            </TableCell>
                                                                            <TableCell className={`${styles.cell} ${styles.bold}`}>
                                                                                <p className={`${styles.text} ${styles.price}`}>$ {addPointsInText(item?.price)}</p>
                                                                            </TableCell>
                                                                            <TableCell className={`${styles.cell} ${styles.action}`}>
                                                                                <IconButton 
                                                                                    title={(rowData?.open && item?._id === rowData?.item?._id) ? "Cerrar" : "Abrir"} 
                                                                                    onClick={() => setRowData({item: item, open: !rowData?.open})}
                                                                                    className={`${styles.arrow} ${rowData?.open && item?._id === rowData?.item?._id && styles.open}`}
                                                                                >
                                                                                    <KeyboardArrowDownIcon className={styles.icon}/>
                                                                                </IconButton>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                                                <Collapse in={rowData?.open && item?._id === rowData?.item?._id} timeout="auto" unmountOnExit>
                                                                                    {
                                                                                        rowData?.item?.offers && rowData?.item?.offers.map((offer: any, index: number) => {
                                                                                            let stateCode = "";
                                                                                            switch(offer?.status){
                                                                                                case "accepted":
                                                                                                    stateCode = styles.green
                                                                                                    break;
                                                                                                case "rejected":
                                                                                                    stateCode = styles.red
                                                                                                    break;
                                                                                                case "cancelled":
                                                                                                case "pause":
                                                                                                    stateCode = styles.gray
                                                                                                    break;
                                                                                                default:
                                                                                                    stateCode = styles.blue
                                                                                                    break;
                                                                                            }
                                                                                            return (
                                                                                                <>
                                                                                                    <div className={`${styles.offer} ${styles.hover}`} onClick={() => setOffersModal({open: true, offer: offer})}>
                                                                                                        <div style={{flex: .93, display: "flex", gap: "48px"}}>
                                                                                                            <p className={styles.info}>
                                                                                                                <span className={styles.price}><strong>$ {addPointsInText(offer.offer)}</strong></span>
                                                                                                                <span className={styles.date}>{moment(offer.created_at).format("DD MMM HH:mm")}</span>
                                                                                                            </p>
                                                                                                            <div style={{flex: 1, display: "flex", justifyContent: "flex-end", gap: "24px"}}>
                                                                                                                <p className={`${styles.chip} ${stateCode}`}>
                                                                                                                    { translations(offer.status) }
                                                                                                                </p>
                                                                                                                <div className={styles.actions}>
                                                                                                                    {
                                                                                                                        section === "sent" &&
                                                                                                                        <IconButton 
                                                                                                                            title="Ver la publicación"
                                                                                                                            onClick={() => navigate(`/publication/${offer?.publication}`)}
                                                                                                                            className={`${styles.action} ${styles.publication}`}
                                                                                                                        >
                                                                                                                            <OpenInNewIcon className={styles.icon} />
                                                                                                                        </IconButton>
                                                                                                                    }
                                                                                                                    <IconButton 
                                                                                                                        title="Detalle"
                                                                                                                        onClick={() => setOffersModal({open: true, offer: offer})}
                                                                                                                        className={`${styles.action} ${styles.detail}`}
                                                                                                                    >
                                                                                                                        <MoreVertIcon className={styles.icon} />
                                                                                                                    </IconButton>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className={styles.fakeView}/>
                                                                                                        {
                                                                                                            index !== (rowData?.item?.offers?.length - 1) &&
                                                                                                            <Divider />
                                                                                                        }
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Collapse>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </>
                                                                    )
                                                                })
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                        }
                                    </div>
                                    {/* {
                                        !(myOffers?.fetching === "loading" && myOffersAux?.length > 0) &&
                                        <Pagination
                                            page={page}
                                            setPage={setPage}
                                            className={styles.pagination}
                                            total={totalMyOffers}
                                        />
                                    } */}
                                </div>
                                <OffersModal
                                    variant={section === "received" ? "sell" : "buy"}
                                    open={offersModal?.open}
                                    offer={offersModal?.offer}
                                    idPublication={offersModal?.offer?.publication}
                                    onClose={() => setOffersModal({...offersModal, open: false})}
                                />
                            </ProfileCard>
                }
            </>
    )
};
export default EntryPoint;