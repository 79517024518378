import { createSlice } from "@reduxjs/toolkit";
import { AuthStateProps } from "../interfaces";

const initialState: AuthStateProps = {
    fetching: "unknown",
    user: null,
    error: "",
    externalUser: null,
    externalLogin: false,
    fetchingRefreshToken: "unknown",
    refreshToken: "",
    expirationTime: null,
    refreshType: "first_initialization",
    token: "",
    fetchingEdit: "unknown",
    errorEdit: "",
    fetchingPatchResidence: "unknown",
    resetPassword: {
        fetching: "unknown",
        error: false,
        email: ''
    },
    updatePassword: {
        fetching: "unknown",
        error: false
    },
    postImage: {
        fetching: "unknown",
        data: []
    },
    register: {
        fetching: "unknown",
        user: null,
        error: ""
    },
    redirect: "",
    deleteFetching: "unknown",
    reSendValidationFetching: "unknown"
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logInRequest: (state) => {
            state.fetching = "loading";
            state.error = initialState.error;
        },
        logInSuccess: (state, action) => {
            state.error = initialState.error;
            state.fetching = "success";
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.refreshToken = action.payload.refreshToken;
            state.expirationTime = action.payload.expirationTime;
        },
        logInFailure: (state, action) => {
            state.fetching = "failure";
            state.error = action.payload;
        },
        externalLogInSuccess: (state, action) => {
            state.error = initialState.error;
            state.fetching = "success";
            state.externalUser = action.payload.user;
            state.externalLogin = true;
        },
        cleanExternalUser: (state) => {
            state.externalUser = initialState.externalUser;
        },
        cleanExternalLogin: (state) => {
            state.externalLogin = initialState.externalLogin;
        },
        refreshTokenRequest: (state, action) => {
            state.fetchingRefreshToken = "loading";
            state.refreshType = action.payload;
        },
        refreshTokenSuccess: (state, action) => {
            state.fetchingRefreshToken = "success";
            state.refreshToken = action.payload.refreshToken;
            state.token = action.payload.token;
            state.expirationTime = action.payload.expirationTime;
            state.refreshType = action.payload.refreshType;
            state.user = action.payload.user;
        },
        refreshTokenFailure: (state) => {
            state.fetchingRefreshToken = "failure";
        },
        initRefreshType: (state) => {
            state.refreshType = "";
        },
        editUserRequest: (state) => {
            state.fetchingEdit = "loading";
        },
        editUserSuccess: (state, action) => {
            state.fetchingEdit = "success";
            state.user = action.payload;
        },
        editUserFailure: (state, action) => {
            state.fetchingEdit = "failure";
            state.errorEdit = action.payload;
        },
        logOut: (state) => {
            state.error = initialState.error;
            state.token = initialState.token;
            state.expirationTime = initialState.expirationTime;
            state.refreshToken = initialState.refreshToken;
            state.user = initialState.user;
            state.fetching = initialState.fetching;
            state.externalLogin = initialState.externalLogin;
        },
        resetPasswordRequest: (state) => {
            state.resetPassword.fetching = "loading";
            state.resetPassword.error = initialState.resetPassword.error;
        },
        resetPasswordSuccess: (state, action) => {
            state.resetPassword.fetching = "success";
            state.resetPassword.error = initialState.resetPassword.error;
            state.resetPassword.email = action.payload;
        },
        resetPasswordFailure: (state, action) => {
            state.resetPassword.fetching = "failure";
            state.resetPassword.error = action.payload;
        },
        updatePasswordRequest: (state) => {
            state.updatePassword.fetching = "loading";
            state.updatePassword.error = initialState.updatePassword.error;
        },
        updatePasswordSuccess: (state) => {
            state.updatePassword.fetching = "success";
            state.updatePassword.error = initialState.updatePassword.error;
        },
        updatePasswordFailure: (state, action) => {
            state.updatePassword.fetching = "failure";
            state.updatePassword.error = action.payload;
        },
        registerUserRequest: (state) => {
            state.register.fetching = "loading";
            state.register.error = initialState.register.error;
        },
        registerUserSuccess: (state, action) => {
            state.register.fetching = "success";
            state.register.user = action.payload;
        },
        registerUserFailure: (state, action) => {
            state.register.fetching = "failure";
            state.register.error = action.payload;
        },
        postUserImageRequest: (state) => {
            state.postImage.fetching = "loading";
        },
        postUserImageSuccess: (state, action) => {
            state.postImage.fetching = "success";
            state.postImage.data = action.payload;
        },
        postUserImageFailure: (state) => {
            state.postImage.fetching = "failure";
        },
        deleteUserRequest: (state) => {
            state.deleteFetching = "loading";
        },
        deleteUserSuccess: (state) => {
            state.deleteFetching = "success";
        },
        deleteUserFailure: (state) => {
            state.deleteFetching = "failure";
        },
        cleanDeleteUser: (state) => {
            state.deleteFetching = initialState.deleteFetching;
        },
        patchResidenceRequest: (state) => {
            state.fetchingPatchResidence = "loading";
        },
        patchResidenceSuccess: (state, action) => {
            state.fetchingPatchResidence = "success";
            state.user = action.payload;
        },
        patchResidenceFailure: (state) => {
            state.fetchingPatchResidence = "failure";
        },
        cleanResidenceFetching: (state) => {
            state.fetchingPatchResidence = initialState.fetchingPatchResidence;
        },
        cleanErrRegister: (state) => {
            state.fetching = initialState.fetching;
            state.resetPassword.fetching = initialState.resetPassword.fetching;
            state.updatePassword.fetching = initialState.updatePassword.fetching;
            state.register.fetching = initialState.register.fetching;
            state.register.error = initialState.register.error;
            state.deleteFetching = initialState.deleteFetching;
        },
        cleanEditUser: (state) => {
            state.fetchingEdit = initialState.fetchingEdit;
            state.errorEdit = initialState.errorEdit;
        },
        setLoginRedirection: (state, action) => {
            state.redirect = action.payload;
        },
        cleanPostImage: (state) => {
            state.postImage = initialState.postImage;
        },
        reSendValidationRequest: (state) => {
            state.reSendValidationFetching = "loading";
        },
        reSendValidationSuccess: (state) => {
            state.reSendValidationFetching = "success";
        },
        reSendValidationFailure: (state) => {
            state.reSendValidationFetching = "failure";
        },
        cleanReSendValidation: (state) => {
            state.reSendValidationFetching = initialState.reSendValidationFetching;
        },
    },
});

export const {
    logInRequest,
    logInSuccess,
    logInFailure,
    externalLogInSuccess,
    cleanExternalUser,
    cleanExternalLogin,
    logOut,
    refreshTokenRequest,
    refreshTokenSuccess,
    refreshTokenFailure,
    initRefreshType,
    editUserRequest,
    editUserSuccess,
    editUserFailure,
    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure,
    updatePasswordRequest,
    updatePasswordSuccess,
    updatePasswordFailure,
    registerUserRequest,
    registerUserSuccess,
    registerUserFailure,
    cleanErrRegister,
    cleanEditUser,
    setLoginRedirection,
    postUserImageRequest,
    postUserImageSuccess,
    postUserImageFailure,
    patchResidenceRequest,
    patchResidenceSuccess,
    patchResidenceFailure,
    cleanResidenceFetching,
    cleanPostImage,
    deleteUserRequest,
    deleteUserSuccess,
    deleteUserFailure,
    cleanDeleteUser,
    reSendValidationRequest,
    reSendValidationSuccess,
    reSendValidationFailure,
    cleanReSendValidation
} =
    authSlice.actions;

export default authSlice.reducer;
