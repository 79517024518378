import { AnyAction } from "redux";
import { getNotifications } from "../../actions/notifications";
import { editUserSuccess, refreshTokenSuccess } from "../../reducers/auth";
import { cleanProfileFetching } from "../../actions/profile";
import { initRefreshType, refreshTokenRequest } from "../../actions/auth";
import { REHYDRATE } from "redux-persist";
import { getDocumentsTypes } from "../../actions/documents";

export default function userMiddleware(store: any) {
    return (next: any) => async (action: AnyAction) => {
        const result = next(action);
        if (action.type === REHYDRATE && store.getState().auth?.fetchingRefreshToken !== "loading") {
            refreshTokenRequest("first_initialization")(store.dispatch, store.getState);
        }
        if (action.type === refreshTokenSuccess) {
            if(store.getState().auth?.refreshType === "first_initialization"){
                initRefreshType()(store.dispatch);
                getDocumentsTypes()(store.dispatch);
                getNotifications()(store.dispatch, store.getState);
            }
        }
        if (action.type === editUserSuccess) {
            setTimeout(() => {
                cleanProfileFetching()(store.dispatch);
            },300)
        }
        return result;
    }
};