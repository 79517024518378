import { connect } from "react-redux";
import { bindActionCreators, Dispatch, AnyAction } from "@reduxjs/toolkit";
import { TagsContainerProps } from "../../interfaces/containers";
import Description from "../../pages/Sell/Description";
import { setTags, setDescription } from "../../actions/salePost";

function mapStateToProps(state: TagsContainerProps) {
    return{
        brands: state.salePost.brands,
        models: state.salePost.models,
        color: state.salePost.color,
        tags: state.salePost.tags,
        plate: state.salePost.plate,
        isFoundPlate: state.salePost.isFoundPlate,
        description: state.salePost.description
    }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators({
        setTags,
        setDescription
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(Description);