
export const CAR_COLORS = [
    {
        keys: ['blanco']
    },
    {
        keys: ['negro']
    },
    {
        keys: ['gris']
    },
    {
        keys: ['plata', 'plateado']
    },
    {
        keys: ['beige']
    },
    {
        keys: ['dorado']
    },
    {
        keys: ['verde']
    },
    {
        keys: ['azul']
    },
    {
        keys: ['morado']
    },
    {
        keys: ['rojo']
    },
    {
        keys: ['otro']
    },
];

export const optionsAccidentsReported = [
    {
        id: "withAccidents",
        name: "Con accidentes",
        value: false
    },
    {
        id: "withoutAccidents",
        name: "Sin accidentes",
        value: true
    }
];

export const optionsPersonalUse = [
    {
        id: "personalUse",
        name: "Uso personal",
        value: true
    },
    {
        id: "comercialUse",
        name: "Uso comercial",
        value: false
    },
];

export const optionsSoleOwner = [
    {
        id: "yes",
        name: "Si",
        value: true
    },
    {
        id: "no",
        name: "No",
        value: false
    },
];

export const translations = (status: string) => {
    if (status === "counteroffered") return "Contraoferta";
    if (status === "pause") return "Pausado";
    if (status === "pending") return "Pendiente";
    if (status === "approved") return "Aprobado";
    if (status === "cancelled") return "Cancelado";
    if (status === "rejected") return "Rechazado";
    if (status === "accepted") return "Aceptado";
    if (status === "paid") return "Pagado";
    if (status === 'processing' || status === "in_progress") return "En proceso";
    if (status === "in_evaluation") return "En evaluación";
    if (status === "conventional") return "Convencional";
    if (status === "intelligent") return "Inteligente";
    if (status === "finished") return "Finalizado";
    return "";
};

export const storeLinks = {
    android: "http://play.google.com/store/apps/details?id=com.mavimovil.linzeapp",
    ios: "http://apps.apple.com/us/app/linze-car/id1632630015"
};