import { useEffect, useState } from 'react';
import { TextFieldSharedProps } from '../../interfaces/components';
import { FormControl, TextField, InputAdornment, IconButton, CircularProgress, Autocomplete } from '@mui/material';
import styles from './textField.module.scss';
import { capitalize, formatInput } from '../../helpers/app';
import { checkerType } from "../../helpers/app";
import Icon from '@mdi/react';
import { mdiEyeOff, mdiEye, mdiMagnify } from '@mdi/js';
import CheckboxShared from '../Checkbox/Checkbox';
import { useDebounce, useOnGetPlaces } from '../../helpers/hooks';

const TextFieldShared = ({
    type = "text",
    variant = "normal",
    label = "",
    name,
    className,
    checker,
    value,
    error,
    touched,
    onChange = () => { },
    onEnter = () => { },
    onFocus = () => { },
    onBlur = () => { },
    disabled = false,
    autoFocus,
    fullWidth = true,
    loading = false,
    message = "",
    icon = "$",
    hidden = false,
    color = "black",
    direction = "ltr",
    size,
    multiline = false
}: TextFieldSharedProps) => {
    const { getPlaces, places } = useOnGetPlaces();
    const [showValue, setShowValue] = useState<boolean>(false);
    const [borderlessValue, setBorderlessValue] = useState<string>("0");
    const [inputNormalValue, setInputNormalValue] = useState<string>("");
    const [searchInput, setSearchInput] = useState<string>("");
    const [showPassValidations, setShowPassValidations] = useState<boolean>(false);
    const [passValidation, setPassValidation] = useState<any>({
        specialCaracter: false,
        mayus: false,
        correctLength: false,
        number: false
    });
    const [debounceAddress, setDebounceAddress] = useState<string>("");
    const [openDirection, setOpenDirection] = useState<boolean>(true);
    const searchAddress = useDebounce(debounceAddress, 800);
    const [coords, setCoords] = useState({ lat: 0, long: 0 })
    const [address, setAddress] = useState({address: "", coords: { lat: 0, long: 0} });
    const [directionInput, setDirectionInput] = useState<any>({value: "", error: ""});

    const getPosition = () => {
        navigator.geolocation.getCurrentPosition((position: any) => {
            setCoords({lat: position.coords.latitude, long: position.coords.longitude});
        });
    }

    const handleChangeDirection = (value: any) => {
        setDebounceAddress(value)
        setDirectionInput({...directionInput, value: value});
    }

    const handleFocusPlaces = () => {
        if(places?.data && places?.data.length > 0) setOpenDirection(true);
    }

    const handleChangeAddress = (newValue: any) => {
        setDirectionInput({...directionInput, value: newValue?.formatted_address})
        setAddress({
            address: newValue?.formatted_address || "",
            coords: {
                lat: newValue?.geometry.location.lat || "",
                long: newValue?.geometry.location.lng || ""
            }
        });
        if(onChange) onChange({
            value: newValue?.formatted_address || "",
            coords: {
                lat: newValue?.geometry.location.lat || "",
                long: newValue?.geometry.location.lng || ""
            }
        });
        setOpenDirection(false);
    }
    
    const handleChangeSearchInput = (value: any) => {
        setSearchInput(value);
        if(onChange) onChange(value);
    }

    const handleChangeBorderlessInput = (event: any) => {
        let value = "0";
        let inputValue = event.target.value;
        if(/^0[1-9]/.test(inputValue)){
            inputValue = inputValue.replace(/^0*/, "");
        }
        if(inputValue === (direction === "ltr" ? icon + "0" : "0" + icon) || inputValue === icon || inputValue === ""){
            setBorderlessValue(value);
        }else{
            const { onlyNumber, formattedNumber } = formatInput(inputValue);
            setBorderlessValue(formattedNumber)
            value = onlyNumber;
        }
        if(onChange) onChange(value);
    }

    const handleChangeNormalInput = (event: any) => {
        const value = event.target.value;
        let validValue: boolean = true;
        if(checker){
            validValue = checkerType(value, checker);
        }
        if(validValue){
            setInputNormalValue(value);
            if(type === "password"){
                const passValidate = {
                    specialCaracter: /\W/.test(value),
                    mayus: /[A-Z]+/.test(value),
                    correctLength: value?.length > 7,
                    number: /\d/.test(value)
                };
                setPassValidation(passValidate);
                if(onChange) onChange({value, passValidate});
            }else{
                if(onChange) onChange(value);
            }
        }
    }

    const handleBlur = () => {
        if(onBlur) onBlur();
        if(type === "password"){
            setShowPassValidations(false);
        }
    }

    const handleFocus = (event: any) => {
        if(variant !== "borderless"){
            if(onFocus) onFocus();
        }
        
        if(type === "password"){
            setShowPassValidations(true);
        }
        
        const { onlyNumber } = formatInput(event.target.value);
        if(onlyNumber.toString() === "0"){
            event.target.select();
        }
    }

    const handleMouseDownValue = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        if((value || value === "") && variant === "normal"){
            setInputNormalValue(value);
        }
        if(value && variant === "borderless"){
            const valueInput = value.toString();
            if(valueInput !== "0"){
                const { formattedNumber } = formatInput(valueInput);
                setBorderlessValue(formattedNumber)
            }
        }
        if((value || value === "") && variant === "search"){
            setSearchInput(value);
        }
        if((value || value === "") && variant === "location"){
            setDirectionInput({...directionInput, value: value});
        }
    }, [value]);

    useEffect(() => {
        if(places?.fetching === "success" && places?.data.length > 0){
            setOpenDirection(true);
            setDirectionInput({...directionInput, error: ""});
        }
        if((places?.fetching === "success" || places?.fetching === "failure") && places?.data.length === 0){
            setDirectionInput({...directionInput, error: "No hemos encontrado esa dirección, realiza una búsqueda más precisa"});
        }
    }, [places])

    useEffect(() => {
        if(variant === "location" && searchAddress.length > 0){
            getPlaces && getPlaces(searchAddress, coords);
        }
    }, [searchAddress]);

    useEffect(() => {
        if(variant === "location"){
            getPosition();
        }
    }, []);

    return (
        <>
            {
                variant === "normal"
                ?
                    <>
                        <FormControl fullWidth={fullWidth} className={`${styles.formControl} ${color === "white" && styles.white} ${variant === "normal" ? styles.normal : styles.borderless} ${className}`} error={touched && Boolean(error)}>
                            <TextField
                                type={
                                    (((type === "password") || hidden) && showValue)
                                        ? "text"
                                        : (type === "password" || hidden)
                                            ? "password"
                                            : type
                                }
                                variant="filled"
                                disabled={disabled}
                                autoComplete="off"
                                id={name}
                                name={name}
                                label={label}
                                value={inputNormalValue}
                                autoFocus={autoFocus}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={(e) => handleChangeNormalInput(e)}
                                onKeyDown={(ev) => { (ev.key === 'Enter') && onEnter(ev) }}
                                InputLabelProps={{
                                    className: styles.label,
                                }}
                                InputProps={{
                                    classes: {
                                        focused: styles.focus
                                    },
                                    className: `${styles.input} ${multiline && styles.height}` ,
                                    disableUnderline: true,
                                    endAdornment: (
                                        loading 
                                        ? 
                                            <InputAdornment position="end">
                                                <CircularProgress
                                                    size={styles.loader}
                                                    classes={{
                                                        colorPrimary: styles.loader
                                                    }}
                                                />
                                            </InputAdornment>
                                        :
                                            ((type === "password") || hidden) &&
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowValue(!showValue)}
                                                    onMouseDown={handleMouseDownValue}
                                                    className={styles.showValue}
                                                >
                                                    <Icon path={showValue ? mdiEye : mdiEyeOff} title="show/hide value" className={styles.icon} />
                                                </IconButton>
                                            </InputAdornment>
                                    )
                                }}
                                multiline={multiline}
                            />
                            {
                                !showPassValidations && message && error
                                ?
                                    <div className={styles.bottomMessage}>
                                        <p className={styles.message}>{capitalize(message)}</p>
                                        <p className={styles.error}>{capitalize(error)}</p>
                                    </div>
                                :
                                    message
                                    ?   <p className={styles.onlyMessage}>{capitalize(message)}</p>
                                    :   <p className={styles.onlyError}>{capitalize(error)}</p>
                            }
                            {
                                showPassValidations &&
                                <>
                                    <div className={styles.passwordValidations}>
                                        <p className={styles.message}>Tu clave debe incluir:</p>
                                        <CheckboxShared 
                                            disabled 
                                            checked={passValidation?.specialCaracter} 
                                            circular
                                            className={styles.item}
                                            text={<p>1 caracter especial</p>}
                                            size="small"
                                            id="passwordSpecialCaracter"
                                        />
                                        <CheckboxShared 
                                            disabled 
                                            checked={passValidation?.mayus} 
                                            circular
                                            className={styles.item}
                                            text={<p>1 mayúscula</p>}
                                            size="small"
                                            id="passwordMayus"
                                        />
                                        <CheckboxShared 
                                            disabled 
                                            checked={passValidation?.correctLength} 
                                            circular
                                            className={styles.item}
                                            text={<p>Al menos 8 caracteres</p>}
                                            size="small"
                                            id="passwordCorrectLength"
                                        />
                                        <CheckboxShared 
                                            disabled 
                                            checked={passValidation?.number} 
                                            circular
                                            className={styles.item}
                                            text={<p>1 número</p>}
                                            size="small"
                                            id="passwordNumber"
                                        />
                                    </div>
                                </>
                            }
                        </FormControl>
                    </>
                :
                variant === "borderless"
                ?
                    <div className={`${styles.inputBorderless} ${fullWidth && styles.fullWidth} ${className}`}>
                        {
                            direction === "ltr" &&
                            <p className={styles.symbol} style={size ? { fontSize: `${size}px` } : {}}>{icon}</p>
                        }
                        <input
                            type="text"
                            value={borderlessValue}
                            onChange={(e) => handleChangeBorderlessInput(e)}
                            placeholder={label}
                            className={styles.input}
                            onKeyDown={(ev) => { (ev.key === 'Enter') && onEnter(ev) }}
                            size={borderlessValue.length}
                            autoFocus={autoFocus}
                            style={size ? { fontSize: `${size}px` } : {}}
                        />
                        {
                            direction === "rtl" &&
                            <p className={styles.symbol} style={size ? { fontSize: `${size}px` } : {}}>{icon}</p>
                        }
                    </div>
                :
                variant === "search"
                ?
                    <div className={`${styles.inputSearch} ${className}`}>
                        <input
                            type="text"
                            value={searchInput}
                            onChange={(e: any) => handleChangeSearchInput(e.target.value)}
                            placeholder={label}
                            className={styles.input}
                            onKeyDown={(ev) => { (ev.key === 'Enter') && onEnter(ev) }}
                        />
                        <IconButton aria-label='Buscar' onClick={(ev: any) => onEnter(ev)}>
                            <Icon path={mdiMagnify} title="Buscar" className={styles.icon} />
                        </IconButton>
                    </div>
                :
                    <div className={`${styles.inputLocation} ${className}`}>
                        <TextFieldShared
                            label="Dirección" 
                            name="direction"
                            value={directionInput?.value}
                            error={directionInput?.error}
                            onFocus={handleFocusPlaces}
                            onBlur={() => setOpenDirection(false)}
                            className={styles.input}
                            onChange={handleChangeDirection}
                            loading={places?.fetching === "loading"}
                        />
                        <Autocomplete
                            value={address}
                            onChange={(event: any, newValue: any) => {
                                handleChangeAddress(newValue)
                            }}
                            fullWidth
                            clearOnBlur
                            selectOnFocus
                            open={openDirection}
                            filterOptions={(x) => x}
                            handleHomeEndKeys
                            id="autoCompleteTextfield"
                            options={places?.data}
                            getOptionLabel={(option: any) => {
                                if (option.address) {
                                    return option?.address;
                                }
                                return option?.formatted_address || ""
                            }}
                            renderOption={(props: any, option: any) => <li {...props} key={option?.place_id}>{option?.formatted_address || ""}</li>}
                            freeSolo
                            renderInput={(params) => (
                                <TextField 
                                    {...params} 
                                    fullWidth 
                                    label="Confirma tu dirección" 
                                    className={styles.locationSelector} 
                                    onChange={handleChangeDirection}
                                />
                            )}
                        />
                    </div>
            }
        </>
    );
};

export default TextFieldShared;