import { useMemo } from "react";
import styles from "./modalTopDownloadApp.module.scss";
import { images } from '../../constants/images';
import { ModalTopDownloadAppProps } from '../../interfaces/components';
import { IconButton } from "@mui/material";
import { mdiClose } from '@mdi/js';
import Icon from "@mdi/react";

const ModalTopDownloadApp = ({
    open,
    setOpen
}: ModalTopDownloadAppProps) => {
    const device = useMemo(() => {
        const platform = window.navigator?.platform.toLowerCase();
        if(platform.match("mac") || platform.match("iphone") || platform.match("ipad") || platform.match("ipod") || platform.match("pike")){
            return "ios"
        }else{
            return "android"
        }
    }, []);

    const handleClickDownload = () => {
        if(device === "ios"){
            window.open("https://apps.apple.com/us/app/linze-car/id1632630015", "_blank");
        }else{
            window.open("https://play.google.com/store/apps/details?id=com.mavimovil.linzeapp", "_blank");
        }
    }
    
    return(
        open 
        ?
            <div className={styles.downloadApp}>
                <IconButton id="icon" className={styles.close} onClick={() => setOpen(false)}>
                    <Icon path={mdiClose} className={styles.icon} title="Cerrar" />
                </IconButton>
                <img src={images.launcherApp} alt="Logo" />
                <p>
                    <span className={styles.title}>Linze</span>
                    <span className={styles.text}>En {device === "ios" ? "App store" : "Google play"}</span>
                </p>
                <div className={styles.button} onClick={handleClickDownload}>
                    <p>Descargar</p>
                </div>
            </div>
        :
            null
    )
}

export default ModalTopDownloadApp;