import { useEffect, useMemo, useState } from "react";
import styles from "./offersModal.module.scss";
import { OffersModalProps } from "../../../../../interfaces/components";
import Modal from "../../../../../components/Modal/Modal";
import ButtonShared from "../../../../../components/Button/Button";
import { IconButton } from "@mui/material";
import Icon from "@mdi/react";
import { mdiClose } from '@mdi/js';
import TextFieldShared from "../../../../../components/TextField/TextField";
import { addPointsInText, formatInput, removePointsInText } from "../../../../../helpers/app";
import TimeLine from "../TimeLine";
import { useToast } from "../../../../../helpers/hooks";
import ModalAcceptRejectOffer from "../../../../../components/ModalAcceptRejectOffer";
import ButtonsLoader from "./Loader/ButtonsLoader";

const OffersModal = ({
    open, 
    publication,
    offer,
    onClose,
    counterOffers,
    getCounterOffers,
    cleanGetCounterOffers,
    postCounterOffers, 
    cleanPostCounterOffers, 
    putCounterOffers, 
    cleanPutCounterOffers,
    putOffersPublication,
    offerReducer,
    cleanOfferPut,
    getOffersPublication,
    idPublication = null,
    getPublication,
    publicationReducer,
    cleanPublication,
    variant = "buy"
}: OffersModalProps) => {
    const toast = useToast();
    const [countOffer, setCountOffer] = useState<any>({value: "", error: ""});
    const [typeLastSection, setLastSection] = useState<string>("offer");
    const [openCountOffer, setOpenCountOffer] = useState<boolean>(false);
    const [acceptRejectOffer, setAcceptRejectOffer] = useState<any>({open: false, type: "accepted", price: "0"});

    const offersDataNormalized = useMemo(() => {
        return counterOffers?.data && counterOffers?.data.map((item: any) => (
            {
                title: item.title ?? "",
                price: `$ ${addPointsInText(item.price) ?? 0}`,
                description: item?.description ?? ""
            }
        ))
    }, [counterOffers?.data]);

    const handleChangeCountOffer = (value: any) => {
        const { formattedNumber } = formatInput(value);
        setCountOffer({...countOffer, value: formattedNumber});
    }

    const onCounterOffer = () => {
        postCounterOffers(offer?._id, parseInt(removePointsInText(countOffer?.value)));
    }

    const onReject = () => {
        setAcceptRejectOffer({...acceptRejectOffer, price: offersDataNormalized[offersDataNormalized.length - 1].price, type: "rejected"});
        if(typeLastSection === "offer"){
            putOffersPublication(offer?._id, "rejected");
        }else{
            const counterOffer = counterOffers?.data.find((item: any) => item?.type === (variant === "buy" ? "counteroffer" : "final-counteroffer"));
            putCounterOffers(counterOffer?._id, "rejected");
        }
    }

    const onAccept = () => {
        setAcceptRejectOffer({...acceptRejectOffer, price: offersDataNormalized[offersDataNormalized.length - 1].price, type: "accepted"});
        if(typeLastSection === "offer"){
            putOffersPublication(offer?._id, "accepted");
        }else{
            const counterOffer = counterOffers?.data.find((item: any) => item?.type === (variant === "buy" ? "counteroffer" : "final-counteroffer"));
            putCounterOffers(counterOffer?._id, "accepted");
        }
    }

    const onCloseAcceptRejectOffer = () => {
        onClose();
        setAcceptRejectOffer({...acceptRejectOffer, open: false});
    }

    useEffect(() => {
        if(counterOffers?.fetching === "failure"){
            toast("Ha ocurrido un error al mostrar la oferta en detalle");
            cleanGetCounterOffers();
            onClose();
        }
        if(counterOffers?.fetching === "success"){
            setLastSection((counterOffers?.data && counterOffers?.data[counterOffers?.data.length - 1].type) ?? "offer");
            cleanGetCounterOffers();
            getOffersPublication(publication?._id ?? idPublication);
        }
    }, [counterOffers?.fetching]);

    useEffect(() => {
        if(counterOffers?.post?.fetching === "failure"){
            toast("Ha ocurrido un error al enviar la contraoferta");
            cleanPostCounterOffers();
        }
        if(counterOffers?.post?.fetching === "success"){
            toast("Contraoferta enviada exitosamente");
            setOpenCountOffer(false);
            getCounterOffers(offer?._id);
            getOffersPublication(publication?._id ?? idPublication);
            cleanPostCounterOffers();
        }
    }, [counterOffers?.post?.fetching]);

     // Aceptar o rechazar contraoferta del comprador
     useEffect(() => {
        if(counterOffers?.put?.fetching === "failure"){
            toast(`Ha ocurrido un error al ${acceptRejectOffer.type === "accepted" ? "aceptar" : "rechazar"} la contraoferta`);
            cleanPutCounterOffers();
        }
        if(counterOffers?.put?.fetching === "success"){
            setAcceptRejectOffer({...acceptRejectOffer, open: true});
            cleanPutCounterOffers();
            getOffersPublication(publication?._id ?? idPublication);
        }
    }, [counterOffers?.put?.fetching]);

    // Aceptar o rechazar oferta del comprador
    useEffect(() => {
        if(offerReducer?.put?.fetching === "failure"){
            toast(`Ha ocurrido un error al ${acceptRejectOffer.type === "accepted" ? "aceptar" : "rechazar"} la oferta`);
            cleanOfferPut();
        }
        if(offerReducer?.put?.fetching === "success"){
            setAcceptRejectOffer({...acceptRejectOffer, open: true});
            onClose();
            cleanOfferPut();
            getOffersPublication(publication?._id ?? idPublication);
        }
    }, [offerReducer?.put?.fetching]);

    useEffect(() => {
        if(publicationReducer?.fetching === "failure"){
            cleanPublication();
        }
        if(publicationReducer?.fetching === "success"){
            cleanPublication();
        }
    }, [publicationReducer?.fetching])

    useEffect(() => {
        if(open){
            setOpenCountOffer(false);
            getCounterOffers(offer?._id);
            setAcceptRejectOffer({...acceptRejectOffer, price: offer?.offer});
            if(idPublication){
                getPublication(idPublication);
            }
        }
    }, [open]);

    return(
        <>
            <ModalAcceptRejectOffer
                variant={variant}
                open={acceptRejectOffer?.open}
                onClose={onCloseAcceptRejectOffer}
                offer={typeLastSection === "offer"}
                counteroffer={typeLastSection === "final-counteroffer"}
                price={acceptRejectOffer?.price}
                type={acceptRejectOffer?.type}
            />
            <Modal open={open} onClose={onClose} variant="close" >
                <div className={styles.container}>
                    <div className={`${styles.line} ${styles.verticalLine}`}></div>
                    <div className={`${styles.line} ${styles.horizontalLine}`}></div>
                    <p className={styles.title}>Oferta recibida</p>
                    <div className={styles.timeline}>
                        <TimeLine 
                            data={offersDataNormalized} 
                            loading={counterOffers?.fetching === "loading"} 
                            image={false}
                        />
                    </div>
                    <div className={styles.form}>
                        <p className={styles.publication}>
                            <span className={styles.name}>{publication?.title ?? publicationReducer?.data?.title}</span>
                            <span className={styles.description}>{publication?.primary_description ?? publicationReducer?.data?.primary_description} · {addPointsInText(publication?.secondary_description ?? publicationReducer?.data?.secondary_description)} km</span>
                            <span className={styles.price}>$ {addPointsInText(publication?.price ?? publicationReducer?.data?.price)}</span>
                        </p>
                        <div className={`${styles.buttons} ${openCountOffer && styles.open}`}>
                            <div className={`${styles.line} ${styles.horizontalLine}`}></div>
                            <div className={styles.counterOfferContent}>
                                <div className={styles.header}>
                                    <p className={styles.title}>Realizar contraoferta</p>
                                    <IconButton aria-label='Close' className={styles.close} onClick={() => setOpenCountOffer(false)} disabled={counterOffers?.post?.fetching === "loading"}>
                                        <Icon path={mdiClose} className={styles.icon} />
                                    </IconButton>
                                </div>
                                <div className={styles.input}>
                                    <TextFieldShared
                                        label="Valor"
                                        name="countOffer"
                                        value={countOffer?.value}
                                        onChange={handleChangeCountOffer}
                                        error={countOffer?.error}
                                        className={styles.input}
                                        onEnter={onCounterOffer}
                                    />
                                    <p className={styles.message}>
                                        <span className={styles.bold}>Importante:</span>
                                        <span>Solo puedes enviar una contraoferta</span>
                                    </p>
                                </div>
                                <ButtonShared
                                    onPress={onCounterOffer}
                                    title="Enviar contraoferta"
                                    primary
                                    className={styles.sendButton}
                                    loading={counterOffers?.post?.fetching === "loading"}
                                />
                            </div>
                            {
                                (offer?.status !== "cancelled" && offer.status !== "rejected" && offer.status !== "accepted" && offer.status !== "pause")
                                ?
                                    counterOffers?.fetching === "loading" && !openCountOffer
                                    ?
                                        <ButtonsLoader lastSection={typeLastSection} />
                                    :
                                        ((typeLastSection === "counteroffer" && variant === "buy") 
                                        || (typeLastSection === "offer" && variant === "sell")
                                        || (typeLastSection === "final-counteroffer" && variant === "sell")) &&
                                            <>
                                                {
                                                    typeLastSection !== "final-counteroffer" &&
                                                    <ButtonShared
                                                        onPress={() => setOpenCountOffer(true)}
                                                        title="Realizar contraoferta"
                                                        className={`${styles.counterOffer} ${styles.button}`}
                                                        disabled={counterOffers?.put?.fetching === "loading"}
                                                    />
                                                }
                                                <ButtonShared
                                                    onPress={onReject}
                                                    title="Rechazar"
                                                    secondary
                                                    size="small"
                                                    className={`${styles.reject} ${styles.button}`}
                                                    disabled={(offerReducer?.put?.fetching === "loading" || counterOffers?.put?.fetching === "loading") && acceptRejectOffer.type === "accepted"}
                                                    loading={(offerReducer?.put?.fetching === "loading" || counterOffers?.put?.fetching === "loading") && acceptRejectOffer.type === "rejected"}
                                                />
                                                <ButtonShared
                                                    onPress={onAccept}
                                                    title="Aceptar"
                                                    primary
                                                    size="small"
                                                    className={`${styles.accept} ${styles.button}`}
                                                    disabled={(offerReducer?.put?.fetching === "loading" || counterOffers?.put?.fetching === "loading") && acceptRejectOffer.type === "rejected"}
                                                    loading={(offerReducer?.put?.fetching === "loading" || counterOffers?.put?.fetching === "loading") && acceptRejectOffer.type === "accepted"}
                                                />
                                            </>
                                :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
};

export default OffersModal;