import { Dispatch } from 'redux';
import { api } from "../config/api";
import { GetState } from "../config/store";
import { 
    getSchedulingRequest,
    getSchedulingSuccess,
    getSchedulingFailure,
    getInspectionRequest,
    getInspectionSuccess,
    getInspectionFailure,
    getSchedulingPublicationRequest,
    getSchedulingPublicationSuccess,
    getSchedulingPublicationFailure,
    clearInspection as clearInspectionReducer,
    clearPublication as clearPublicationReducer
} from '../reducers/scheduling';
import { axiosClient } from '../config/axios';

export const getScheduling = (id: string ) => (dispatch: Dispatch) => _getScheduling(id, dispatch);
const _getScheduling = async ( id: string, dispatch: Dispatch ) => {
    try {
        dispatch({type: getSchedulingRequest});

        const response = await axiosClient.get(`${api.transactions}/${id}/delivery-schedules`);

        dispatch({type: getSchedulingSuccess, payload: response?.data.data.delivery_schedule});
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: getSchedulingFailure});
    }
}


export const getSchedulingPublication = (id: string ) => (dispatch: Dispatch) => _getSchedulingPublication(id, dispatch);
const _getSchedulingPublication = async ( id: string, dispatch: Dispatch ) => {
    try {
        dispatch({type: getSchedulingPublicationRequest});

        const response = await axiosClient.get(`${api.transactions}/delivery-schedules/publication/${id}`);

        dispatch({type: getSchedulingPublicationSuccess, payload: response?.data?.data.schedules});
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: getSchedulingPublicationFailure});
    }
}


export const getInspectionSchedule = (id: string) => (dispatch: Dispatch) => _getInspectionSchedules(id, dispatch);
const _getInspectionSchedules = async (id: string, dispatch: Dispatch) => {
    try {
       dispatch({type: getInspectionRequest});

       const response = await axiosClient.get(`${api.transactions}/${id}/inspection-schedules`);
       
       dispatch({type: getInspectionSuccess, payload: response?.data.data});
    } catch (error: any) {
        const errorResponse = error?.response?.data?.error;
        console.log(errorResponse);
        dispatch({type: getInspectionFailure})
    }
}

export const clearPublicationSchedule = () => (dispatch: Dispatch) => _clearPublicationSchedule(dispatch)
const _clearPublicationSchedule = (dispatch: Dispatch) => dispatch({ type: clearPublicationReducer})

export const clearInspectionSchedule = () => (dispatch: Dispatch) => _clearInspectionSchedule(dispatch)
const _clearInspectionSchedule = (dispatch: Dispatch) => dispatch({ type: clearInspectionReducer});