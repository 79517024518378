import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { images } from "../../constants/images";
import { navOptions } from "../../constants/navigation";
import styles from "./navBar.module.scss";
import Notifications from "../../containers/Notifications/NotificationsContainer";
import { NavBarProps } from "../../interfaces/components";
import Dropdown from "../Dropdown";
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { FaRegBell, FaBell } from 'react-icons/fa';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useHistory, useWindowSize } from "use-hooks";
import { tabs } from "../../constants/sidebar";
import Badge from '@mui/material-next/Badge';
import { googleLogout } from '@react-oauth/google';
import { useParams, useLocation } from "react-router";

const NavBar = ({
    user,
    notifications,
    logoutUser,
    cleanAllBuy,
    cleanExternalUser
}: NavBarProps) => {
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const [openMenu, setOpenMenu] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const location = useLocation();
    const [currentRoute, setCurrentRoute] = useState("home")

    const params = useParams();

    useEffect(() => {
        setCurrentRoute(location.pathname)
    },[location])

    const handleLogout = () => {
        cleanAllBuy();
        cleanExternalUser();
        googleLogout();
        logoutUser();
        navigate("/");
        setOpenMenu(false);
    };

    const onNavigateLogin = () => { 
        navigate('/login');
        setOpenMenu(false);
    };

    const onPressNotifications = () => {
        setOpenNotifications(prev => !prev);
        setOpenMenu(false);
    };

    const onPressProfile = () => {
        setOpenProfile(prev => !prev);
    };

    const onPressTab = (tab: any) => {
        navigate(`/profile/${tab?.navigate}`);
        setOpenMenu(false);
    };

    const onPressLink = () => {
        setOpenMenu(false);
    };

    return (
        <header className={styles.navBarContainer}>
            <NavLink className={styles.linzeLink} to={"/"}>
                <img src={images.isoLogoTipo} className={styles.logo} alt="Logo" />
            </NavLink>
            <IconButton className={styles.menu} onClick={() => setOpenMenu(true)}>
                <MenuIcon className={styles.icon} />
            </IconButton>
            <ul id="navBarOptions" className={`${styles.navBarLabels} ${openMenu && styles.open}`}>
                {
                    navOptions.map((option: any, index: number) => {
                        if (((option.to === "/profile" || option.to === "/notifications") && !user) || (option.to === "/login" && user) || (option.to === "/register" && user)) return;
                        if (option.to === "/login" && windowSize.width > 1024){
                            return (
                                <li key={index} className={styles.login} onClick={onNavigateLogin}>
                                    <p>{option.title}</p>
                                </li>
                            )
                        }
                        if (option.to === "/notifications" && windowSize.width > 1024) {
                            let unreaded = 0;
                            return (
                                <li className={styles.notifications} key={index}>
                                    {
                                        notifications && notifications.forEach((item: any) => unreaded = (!item?.read) ? unreaded + 1 : unreaded)
                                    }
                                    {
                                        unreaded > 0 &&
                                        <Badge 
                                            badgeContent={unreaded} 
                                            color="error" 
                                            classes={{ 
                                                root: styles.unReaded,
                                                badge: styles.badge
                                            }} 
                                        />
                                    }
                                    <Dropdown
                                        type="icon"
                                        icon={openNotifications ? <FaBell className={styles.notificationsIcon} /> : <FaRegBell className={styles.notificationsIcon} />}
                                        onPress={onPressNotifications}
                                        className={styles.dropdown}
                                        id={`link-${option.id}`}
                                    >
                                        <Notifications />
                                    </Dropdown>
                                </li>
                            )
                        }
                        if (option.to === "/profile" && user) {
                            return (
                                <li className={styles.profile} key={index}>
                                    <div className={`${styles.picture} ${openProfile && styles.open}`}>
                                        <img src={user?.picture ?? images.profile} className={styles.image} alt="user" onClick={() => navigate("/profile")} />
                                        <Dropdown
                                            icon={
                                                <ArrowDropDownIcon className={styles.icon} />
                                            }
                                            onPress={onPressProfile}
                                            className={styles.dropdown}
                                            id={`link-${option.id}`}
                                        >
                                            <div className={styles.content}>
                                                {
                                                    tabs && tabs.map((tab: any, index: number) => (
                                                        <div key={index} className={styles.tab} onClick={() => onPressTab(tab)}>
                                                            <p>{tab?.label}</p>
                                                        </div>
                                                    ))
                                                }
                                                <div className={`${styles.tab} ${styles.blue}`} onClick={() => handleLogout()}>
                                                    <p>Cerrar sesión</p>
                                                </div>
                                            </div>
                                        </Dropdown>
                                    </div>
                                </li>
                            )
                        }
                        if (option.to) {
                            return (
                                <li key={index} className={styles.liLink}>
                                    <>
                                    <NavLink
                                        key={option.title}
                                        className={`
                                        ${styles.link}
                                        ${({ isActive }: any) => isActive ? styles.activeLink : undefined}
                                    `}
                                        id={`link-${option.id}`}
                                        to={option.to}
                                        title={option?.title}
                                        onClick={onPressLink}
                                    >
                                        {option.title}
                                        {currentRoute === option.realPath ? <div className={styles.currentRoutePoint}/> : null}
                                    </NavLink>
                                    </>
                                </li>
                            )
                        }
                    })
                }
            </ul>
            <div className={`${styles.bkgr} ${openMenu && styles.open}`} onClick={() => setOpenMenu(false)}></div>
        </header>
    )
}
export default NavBar;