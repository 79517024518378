import React from "react";
import { RouteObject } from "../../interfaces/navigation";

const EntryPoint = React.lazy(() => import("../../pages/Linking"));

export const linkRoutes: RouteObject[] = [
    {
        title: "Link",
        path: "/link",
        component: <EntryPoint />
    }
];