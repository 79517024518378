import { useEffect, useMemo, useState } from 'react';
import styles from './detail.module.scss';
import { MyPublicationDetailProps } from '../../../../interfaces/pages';
import ButtonShared from '../../../../components/Button/Button';
import PublicationDetail from '../../../../components/PublicationDetail';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast } from '../../../../helpers/hooks';
import ProfileCard from '../../../../components/ProfileCard/ProfileCard';
import { myPubDetailBreadCrumb } from '../../../../constants/breadCrumbs';
import { useWindowSize } from "use-hooks";
import { capitalize } from '../../../../helpers/app';
import { caracteristics, conforts } from '../../../../constants/images';

const Detail = ({
    user,
    getPublication,
    getArticlesFromPublication,
    publication,
    cleanAllPublication
}: MyPublicationDetailProps) => {
    const params = useParams();
    const toast = useToast();
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const [publicationAux, setPublicationAux] = useState<any>({});

    const handlePressManagement = () => {
        cleanAllPublication();
        navigate(`/profile/my_publications/${publication?.data?._id}/management`);
    }

    const handlePressEdit = () => {
        navigate(`/profile/my_publications/${publication?.data?._id}/edit`);
    }

    const publicationNormalized: any = useMemo(() => {
        const publicationObject = {
            id: publicationAux?.data?._id,
            pictures: publicationAux?.data?.pictures,
            title: publicationAux?.data?.title,
            year: publicationAux?.data?.primary_description,
            mileage: publicationAux?.data?.secondary_description,
            price: publicationAux?.data?.price,
            sale_price: publicationAux?.data?.other_prices?.sale_price,
            liked: publicationAux?.data?.liked,
            chatgpt_description: publicationAux?.data?.chatgpt_description_summary,
            tags: publicationAux?.data?.tags,
            version: publicationAux?.data?.vehicle_data?.version,
            certified: publicationAux?.data?.certified,
            paused: publicationAux?.data?.paused,
            detail: publicationAux?.data?.record ? publicationAux?.data?.detail.concat(publicationAux?.data?.record) : publicationAux?.data?.detail,
            caracteristics: [
                {
                    name: "Modelo",
                    value: publicationAux?.data?.vehicle_data?.model,
                    icon: caracteristics.model
                },
                {
                    name: "Año",
                    value: publicationAux?.data?.primary_description,
                    icon: caracteristics.year
                },
                {
                    name: "Versión",
                    value: (publicationAux?.articles?.data?.version_id !== 0 && publicationAux?.articles?.data?.version_id !== 1) ? capitalize(publicationAux?.articles?.data?.version) : null,
                    icon: caracteristics.version
                },
                {
                    name: "Color",
                    value: capitalize(publicationAux?.articles?.data?.color),
                    icon: caracteristics.color
                },
                {
                    name: "Tipo de combustible",
                    value: capitalize(publicationAux?.articles?.data?.fuel),
                    icon: caracteristics.fuel
                },
                {
                    name: "Puertas",
                    value: publicationAux?.articles?.data?.doors,
                    icon: caracteristics.door
                },
                {
                    name: "Transmisión",
                    value: capitalize(publicationAux?.articles?.data?.transmission),
                    icon: caracteristics.transmission
                },
                {
                    name: "Tipo de carrocería",
                    value: capitalize(publicationAux?.articles?.data?.type),
                    icon: caracteristics.type
                }
            ],
            confort: [
                {
                    name: "Sistema de audio Hi-Fi",
                    value: publicationAux?.articles?.data?.confort?.audio_hifi ? "Si" : "No",
                    icon: conforts.audio_hifi
                },
                {
                    name: "Asientos de cuero",
                    value: publicationAux?.articles?.data?.confort?.leather_seats ? "Si" : "No",
                    icon: conforts.leather_seats
                },
                {
                    name: "Climatizador automático",
                    value: publicationAux?.articles?.data?.confort?.air_filter ? "Si" : "No",
                    icon: conforts.air_filter
                },
                {
                    name: "Computadora a bordo",
                    value: publicationAux?.articles?.data?.confort?.on_board_computer ? "Si" : "No",
                    icon: conforts.on_board_computer
                },
                {
                    name: "Velocidad crucero",
                    value: publicationAux?.articles?.data?.confort?.cruice_speed ? "Si" : "No",
                    icon: conforts.cruice_speed
                },
                {
                    name: "Sensor de estacionamiento",
                    value: publicationAux?.articles?.data?.confort?.parking_sensor ? "Si" : "No",
                    icon: conforts.parking_sensor
                },
                {
                    name: "Conectividad BT / Wifi",
                    value: publicationAux?.articles?.data?.confort?.bt_wifi ? "Si" : "No",
                    icon: conforts.bt_wifi
                },
                {
                    name: "Cristales eléctricos",
                    value: publicationAux?.articles?.data?.confort?.electric_crystals ? "Si" : "No",
                    icon: conforts.electric_crystals
                }
            ]
        }
        return publicationObject;
    }, [publicationAux]);

    useEffect(() => {
        if (publication?.fetching === "failure" || publication?.articles?.fetching === "failure") {
            cleanAllPublication();
            if (publication?.error === "ID_INVALID_FORMAT" || publication?.articles?.error === "ID_INVALID_FORMAT") {
                return navigate("/profile/my_publications");
            }
            toast("Ha ocurrido un error para mostrar la publicación");
        }
        if (publication?.fetching === "success" && publication?.articles?.fetching === "success") {
            setPublicationAux(publication);
        }
    }, [publication?.fetching, publication?.articles?.fetching]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (params.id) {
            getArticlesFromPublication(params?.id);
            getPublication(params?.id);
        } else {
            navigate("/");
        }
    }, [params]);
    
    return(
        <ProfileCard className={styles.myPublicationDetailContainer} breadCrumbs={myPubDetailBreadCrumb(`${publicationNormalized?.title}`)}>
            <PublicationDetail 
                type="myPublication"
                publication={publicationNormalized}
                loading={publication?.fetching === "loading" || publication?.articles?.fetching === "loading"}
            >
                {
                    <>
                        <ButtonShared
                            onPress={handlePressManagement}
                            title="Gestión"
                            tertiary
                            className={styles.management}
                            id="managementPublicationButton"
                            size={(windowSize.width < 768) ? "small" : "normal"}
                        />
                        <ButtonShared
                            onPress={handlePressEdit}
                            title="Editar"
                            primary
                            disabled={publication?.data?.paused || !["published","rejected"].includes(publication?.data?.status_brief?.code)}
                            className={styles.edit}
                            size={(windowSize.width < 768) ? "small" : "normal"}
                        />
                    </>
                }
            </PublicationDetail>
        </ProfileCard>
    )
}

export default Detail;